import React from "react";
import PropTypes from "prop-types";
import StyledFooterMainEverscore from "./FooterMainEverscore.styled";
import { Link, useLocation } from "react-router-dom";
import SVGIcon from "../utility/SVGIcon";
import EmailSubcriptionCTAEverscore
  from "../EmailSubscriptionCTA/EmailSubcriptionCTAEverscore/EmailSubcriptionCTAEverscore";
import LogoLogickit from "../../images/LK_Logo2.png";

const FooterMainEverscore = React.memo(props => {
  const { license_number, simple, brandLogo, selectedTheme } = props
  const year = new Date();
  const currentYear = year.getFullYear();
  const { pathname } = useLocation();
  const isSEOLocationPAge = pathname.includes("/cannabis-delivery-");

  return (
    <>
    <StyledFooterMainEverscore simple={simple} data-testid="footerMain">
      {!simple && !isSEOLocationPAge &&
        <EmailSubcriptionCTAEverscore />
      }
      <div className="logo-container">
        <img src={brandLogo} alt="" className="footer-home__logo"/>
      </div>
      <div className="footer-wrap">
        <ul className="footer-home__links">
          {selectedTheme!=="DeathRowTheme" &&
              <li className="footer-home__link one">
                <Link to="/about" className="t-footer-link-everscore">About</Link>
              </li>
          }

          <li className="footer-home__link two">
            <Link to="/contact" className="t-footer-link-everscore">Contact Us</Link>
          </li>
          {/* <li className="footer-home__link three">
            <Link to="/faq" className="t-footer-link-everscore">FAQ</Link>
          </li> */}
          <li className="footer-home__link four">
            <Link to="/privacy" className="t-footer-link-everscore">Privacy Policy</Link>
          </li>
          <li className="footer-home__link five">
            <Link to="/terms" className="t-footer-link-everscore">Terms</Link>
          </li>
        </ul>

        <p className="t-stay-everscore stay-connected">Stay Connected</p>

        <ul className="footer-home__socials">
          {/* <li className="footer-home__social facebook">
            <a href="https://www.facebook.com" className="t-footer">
              <span className="t-hidden">Facebook</span>
              <SVGIcon name="facebook" />
            </a>
          </li> */}
          {/*<li className="footer-home__social">*/}
          {/*  <a href="https://twitter.com/shopeverscore" className="t-footer">*/}
          {/*    <span className="t-hidden">Twitter</span>*/}
          {/*    <SVGIcon name="twitter" />*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li className="footer-home__social">*/}
          {/*  <a href="https://www.instagram.com/everscore/" className="t-footer">*/}
          {/*    <span className="t-hidden">Instagram</span>*/}
          {/*    <SVGIcon name="instagram" />*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/* <li className="footer-home__social youtube">
            <a href="https://www.youtube.com" className="t-footer">
              <span className="t-hidden">Youtube</span>
              <SVGIcon name="youtube" />
            </a>
          </li> */}
        </ul>

        <p className="t-copyright-everscore footer-home__copywrite">Copyright &copy; {currentYear} Marketplace, all rights reserved.</p>
        
        {license_number && 
          <p className="t-copyright-everscore footer-home__license">Store license number: {license_number}.</p>
        }
        <a href="https://www.logickit.io/" target="_blank" className="t-body footer-home__license d-flex-container">
          Power by &nbsp; <img src={LogoLogickit} height={20} alt="logo"/>
        </a>
      </div>
    </StyledFooterMainEverscore>
    </>
  );
});

FooterMainEverscore.propTypes = {
  brandLogo: PropTypes.string,
  license_number: PropTypes.string,
  simple: PropTypes.bool
};

export default FooterMainEverscore;
