import { lighten } from "polished";

/* Base Colors */
const brand = "#5B765F"
const webGreen = "#00672E"
const webGrey4 = "#545353"
const brandDark = "#183f31";
const brandLight = "#f2eeeb"
const brandTransparent = "rgba(73, 182, 103, 0.26)";
const grayTransparent = "rgba(204, 204, 204, 0.26)";
const white = "#ffffff";
const whiteTransparent = "rgba(255,255,255,0.6)";
const black = "#000000";
const blackTransparent = "rgba(0, 0, 0, 0.8)";
const borderTransparent = "rgba(0, 0, 0, 0.1)";
const modalBackground = "rgba(24 63 49 / 55%)";
const promoBannerBackground = "#e1f88f";

/* Gray Colors */
const gray1 = "#f5f5f5";
const gray2 = "#cccccc";
const gray3 = "#757575";
const gray4 = "#222222";
const gray5 = "#232F20";
const gray7 = "#80807F";
const gray8 = "#CBCACA";

/* Accent Colors */
const accent1 = "#fae0e0";
const accent2 = "#fff5e5";
const accentBeige = "#efe9e4";
const accentBrown = "#a8672d";
const accentDarkBrown = "#f1edea";
const accentBrownTransparent = "rgba(168, 103, 45, 0.68)";
const accentGreen = "#728772";
const accentSage = "#dce2dc";
const accentBlue = "#E8F3F5";
const accentIndica = "#0860ad";
const accentSativa = "#cf4d27";
const accentHybrid = "#0C793B";
const accentCBD = "#7761C6";
const highlight = "#e1f88f";
const highLightLight = "#F0FBC7";
const brandHighLight = "#E1F88F"

/* Misc Colors */
const success = "#3abf7c";
const complete = "#00618C";
const openOrder = "#558158";
const pendingOrder = "#00A875";
const outForDelivery = "#008fa8";
const error = "#f0513b";
const errorForm = "#e24329";
const newErrorForm = "#B53F26";
const info = "#4D9DE0";
const disabled = "#E5E5E5";
const nftGreen = "#107D44";
const saleGreen =  "#16783E";
const onlyLeftRed = "#EC4D2F";

/*bundle Colors */
const bundleBlue = "#0060FF";
const bundleWhite = "#F5F5F5";
const bundleBlack = "#181818";

const bundleGold = "#FBDA12";
const bundleWWarning = "#F92C1C";
const bundleColor = "#FDCC2F";
const bundleGoldDark = "#FFBB01";


const bundleGrey = "#F3F3EF";
const bundleGrey2 = "#cccccc";
const bundleGrey3 = "#757575";
const bundleGrey4 = "#222222";
const bundleModalBG = "rgba(117 117 117 / 15%)";

/*flower Colors */
const flowerBrandLight = "#F9F8EB";
const flowerBrandDark = "#231F20";
const flowerBrandBright = "#DCB7C9";

const flowerWarning = "#D4392F";
const flowerGray2 = "#CCCCCC";
const flowerGray3 = "#757575";

const flowerBrandConst = "#3F5DA7";
const flowerSale = "#6EBC4C";

const brandColor = process.env.REACT_APP_STORE_COLOR || "#333F48"

/*everscore Colors */
const everscoreBrandLight = "#EEDDC1";
const everscoreBrandDark = brandColor;
const everscoreBrandBright = "#F95738";
const everscoreWarning = "#D4392F";
const everscoreGray2 = "#E2E1E1";
const everscoreGray3 = "#757575";
const everscoreBrandConst = "#6A7792";
const everscoreGray8 = "#ADB6C4";
const everscoreGray9 = "#231F20";
const everscoreSale = "#6EBC4C";
const everscoreSauvignon = "#EFEBE8";
const everscoreGray10 = "#697792";
const everscoreGray11 = "#EAE9E3";

const mainFont1 = "Artifex";
const mainFont2 = "Lato";
// --font-DINNextLTPro-MediumCond: 'DINNextLTPro-MediumCond';
// --font-DINNextLTPro-Regular: 'DINNextLTPro-Regular';
// --font-Moche-Bold: 'Moche-Bold';
// --font-Moderat-Bold: 'Moderat-Bold';
// --font-Moderat-Mono: 'Moderat-Mono';
// --font-Moderat-Medium: 'Moderat-Medium';
// --font-NHaasGroteskDSPro-45Lt: 'NHaasGroteskDSPro-45Lt', sans-serif;
// --font-NHaasGroteskDSPro-65Md: 'NHaasGroteskDSPro-65Md', sans-serif;
// --font-NHaasGroteskDSPro-55Rg: 'NHaasGroteskDSPro-55Rg', sans-serif;
export const mainFonts = {
    mainFont1,
    mainFont2
}

export const colors = {
    newErrorForm,
    webGreen,
    webGrey4,
    success,
    complete,
    openOrder,
    pendingOrder,
    outForDelivery,
    error,
    errorForm,
    info,
    disabled,
    promoBannerBackground,

    brand,
    brandDark,
    brandLight,
    brandTransparent,
    white,
    whiteTransparent,
    grayTransparent,
    black,
    blackTransparent,
    borderTransparent,
    gray1,
    gray2,
    gray3,
    gray4,
    gray5,
    gray7,
    gray8,
    accent1,
    accent2,
    accentBeige,
    accentBrown,
    accentDarkBrown,
    modalBackground,
    accentBrownTransparent,
    accentGreen,
    accentSage,
    accentBlue,
    accentIndica,
    accentSativa,
    accentHybrid,
    accentCBD,
    highLightLight,
    brandHighLight,
    highlight,
    nftGreen,
    saleGreen,
    onlyLeftRed,

    bundleBlue,
    bundleWhite,
    bundleBlack,
    bundleGold,
    bundleWWarning, 
    bundleColor,
    bundleGrey,
    bundleGrey2,
    bundleGrey3,
    bundleGrey4,
    bundleModalBG,
    bundleGoldDark,

    flowerBrandLight,
    flowerBrandDark,
    flowerBrandBright,
    flowerWarning,
    flowerGray2,
    flowerGray3,
    flowerBrandConst,
    flowerSale,

    everscoreBrandLight,
    everscoreBrandDark,
    everscoreBrandBright,
    everscoreWarning,
    everscoreGray2,
    everscoreGray3,
    everscoreGray8,
    everscoreGray9,
    everscoreBrandConst,
    everscoreSale,
    everscoreSauvignon,
    everscoreGray10,
    everscoreGray11,
    
    mono: {
        700: black,
        600: lighten(0.2, black),
        500: lighten(0.4, black),
        400: lighten(0.6, black),
        300: lighten(0.7, black),
        200: lighten(0.75, black),
        100: "#fafafa"
    },

    text: {
        black,
        normal: lighten(0.4, black),
        white,
        link: info
    }
};

export const sizes = {
    mainContainer: "1808px",
    borders: {
        radius: 3,
        width: 1.5
    },
    padding: {
        table: {
            normal: "7px 5px",
            compact: "20px 5px"
        },
        input: "1rem 0.8rem",
        select: ".6em 2.2em .5em .8em"
    },

    animations: {
        duration: 250,
        slider: {
            speed: 1000,
            autoplaySpeed: 5000
        }
    },

    icons: {
        size: 12
    },

    fonts: {
        text: "1rem"
    },

    skeleton: {
        height: "1em",
        width: "6em"
    }
};
