import styled from "styled-components";

const HeaderDeliveryInput = styled.div`
  display: grid;
  width: 100%;
  
  @media (max-width: 859px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 860px) {
    grid-template-columns: auto 1fr;
  }

  .header-delivery__heading {
    padding-top: 6px;
    font-size: 25px;
    font-family: var(--font-DINNextLTPro-Regular);

    @media (max-width: 859px) {
      position: absolute !important;
      height: 1px; 
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    }

    @media (min-width: 860px) {
      padding-right: 0;
      position: relative;
      display: inline-flex;
      align-items: center;
      color: ${({theme}) => theme.colors.brandDark};

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 1px;
        height: 30px;
      }
    }
  }

  .header-delivery__label {
    margin-right: auto;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: 859px) {
      width: 100%;
    }

    &:hover,
    &:focus {
      .header-delivery__icon {
        animation: bobbing 0.3s infinite ease-in-out forwards alternate;
      }
    }
    &:before{
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }

  .header-delivery__icon {
    width: 18px;
    height: 18px;
    box-sizing: content-box;
    flex-shrink: 0;
    display: inline-flex;
    fill: ${({theme}) => theme.colors.brandDark};
    transition: var(--button-transition);
    transform-origin: center;

    @media (max-width: 859px) {
      width: 26px;
      height: 26px;
    }

    @media (min-width: 860px) {
      margin-left: 4px;
      width: 28px;
      height: 28px;
    }
  }

  @keyframes bobbing {
    0%{
        transform: rotate(0) translateY(0);
    }

    100%{
        transform: rotate(1deg) translateY(1px);
    }
  }

  .header-delivery__clear {
    width: 26px;
    height: 26px;
    position: absolute;
    right: -10px;
    top: 12px;

    svg {
      fill: ${({theme}) => theme.colors.white};
      width: inherit;
      height: inherit;
    }

    @media (max-width: 859px) {
      top: -5px;
      right: -6px;

      svg {
        width: 28px;
        height: 28px;
      }
    }


    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
  }

  .header-delivery__location-input {
    background-color: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.brandDark};
    outline: none;
    border: none;
    font-size: 25px;
    font-family: var(--font-DINNextLTPro-Regular);
    text-transform: uppercase;

     &:focus {      
      border-color: ${({theme}) => theme.colors.brandDark};
    }
    
    @media (max-width: 859px) {
      padding: 8px 28px 0 8px;
      font-size: 15px;
      width: 100%;
      height: 24px;
    }

    @media (min-width: 860px) {
      padding: 4px 22px 0 0;
      width: 400px;
      height: "";
      border-radius: 0;

      &:focus {
        border-bottom: 3px solid white;
      }
    }
  }
`;

export default HeaderDeliveryInput;