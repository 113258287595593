import styled from "styled-components";

const SimpleModal = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform : ${props => props.shown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity : ${props => props.shown ? '1' : '0'};
  z-index: 10;

  .simple-modal {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 80px;
    padding: 16px 16px 48px 32px;
    width: 100%;
    max-width: 600px;
    min-height: 250px;
    background-color: ${(props) => props.color1};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 12;
    height: ${props => props.aeroPayFastLink ? '50%' : ''};
    overflow: auto;
    max-height: calc(100% - 100px);
  }

  .simple-modal__button {
    margin-bottom: .5rem;
    padding: 8px;
    display: flex;
    margin-left: auto;
    fill: ${({theme}) => theme.colors.gray3};
    color: ${(props) => props.color2};
    outline: none;

    &:hover,
    &:focus {
      fill: ${({theme}) => theme.colors.brandDark};
    }
  }

  .simple-modal__close {
    height: 20px;
    width: 20px;
    fill: inherit;
  }

  .simple-modal__content {
    margin-right: ${({marginRight}) => marginRight ? marginRight : '36px'}
  }

  .background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    cursor: pointer;
    z-index: 11;
  }

  .aero-modal {
    height: 100%;
    overflow: auto;
  }
`;

export default SimpleModal;