/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import StyledModalChangeAddress from "./VerifyModalAddress.styled";
import LocationSearchInput from "../LocationSearchInput/LocationSearchInput";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {checkout, locationActions} from '../../actions/index';
import { connect } from "react-redux";
import LoadingEllipsis from "../LoadingEllipsis/LoadingEllipsis";
import SVGIcon from "../utility/SVGIcon";
import {useWindowSize} from "../../hooks/useWindowSize";
import { addressPT } from "../../config/prop-types";
import {ThemePramsContext} from "../../App";
import {ReactSVG} from "react-svg";
import {history} from "../../helpers";
import {isKiosk, storeType} from "../../config/constants";
import InputAuthorization from "../forms/InputAuthorization/InputAuthorization";
import StyledModalVerification from "../ModalVerification/ModalVerification.styled";
import Tooltip from "../Tooltip/Tooltip";

let zipCode = null;

const VerifyModalAddress = props => {
    const { dispatch, address, isShown, locationPhone, brandLogo, pickupLocations, curbsideLocations} = props;
    const { width } = useWindowSize();
    const [terminalId, setTerminalId] = useState('');
    const [blog, setBlog] = useState(false)

    const [deliveryLocation, setDeliveryLocation] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        delivery_type: 'delivery'
    });
    const [pickupLocationInfo, setPickupLocationInfo] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        delivery_type: 'is_pickup'
    });
    const [curbsideLocationInfo, setCurbsideLocationInfo] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        delivery_type: 'is_curbside'
    });
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [isAddress, setIsAddress] = useState(true);
    const [deliveryType, changeDeliveryType] = useState("delivery");

    useEffect(() => {
        dispatch(locationActions.getPickupLocations())
        dispatch(locationActions.getCurbsideLocations());
    },[])
    useEffect(() => {
        if(isShown){
            document.body.classList.add("no-scroll");
            const appContainer = document.querySelector(".App")
            if(appContainer) {
                appContainer.classList.add("has-blur");
            }
        }
    }, []);
    useEffect(() => {
        if(width <= 768 && isShown) {
            document.body.classList.add("no-scroll");
        } else if(width > 768 && isShown) {
            document.body.classList.add("has-scroll");
        }
        return () => {
            document.body.classList.remove("no-scroll");
        }
    }, [width, isShown]);

    useEffect(() => {
        if(deliveryStatus === "notDelivery") {
            setIsDelivery(true)
        } else {
            setIsDelivery(false)
        }
    }, [deliveryStatus]);

    useEffect(() => {
        dispatch(locationActions.selectedLocation());
    }, []);

    useEffect(() => {
        if(address){
            const data = {
                ...deliveryLocation,
                address: address.address,
                lat: address.lat,
                lng: address.lng,
                zip: address.zip,
            };
            setDeliveryLocation(data);
            handleOnSelect(address.address)
        }
    }, [ address, isShown ]);
    const modifyPickupLocation = (location) => {
        return  {
            ...pickupLocationInfo,
            address: location?.address,
            lat: location?.lat,
            lng: location?.lon,
            zip: location?.zip,
            city: location?.city,
            state: location?.state,
        };
    }
    const modifyCurbsideLocation = (location) => {
        return  {
            ...curbsideLocationInfo,
            address: location?.address,
            lat: location?.lat,
            lng: location?.lon,
            zip: location?.zip,
            city: location?.city,
            state: location?.state,
        };
    }
    const selectPickupLocation = (location) => {
        const data = modifyPickupLocation(location)
        setPickupLocationInfo(data);
    }

    useEffect(() => {
        if(pickupLocations && pickupLocations.length > 0){
            const data = modifyPickupLocation(pickupLocations[0])
            setPickupLocationInfo(data);
        }
    }, [ pickupLocations ]);
    const selectCurbsideLocation = (location) => {
        const data = modifyCurbsideLocation(location)
        setCurbsideLocationInfo(data);
    }
    useEffect(() => {
        if(curbsideLocations && curbsideLocations.length>0){
            const data = modifyCurbsideLocation(curbsideLocations[0])
            setCurbsideLocationInfo(data);
        }
    }, [ curbsideLocations ]);
    useEffect(() => {
        if(window.location.pathname == '/blog'){
            setBlog(true)
        }
    }, []);
    const noLinkAction = (e) => {
        e.preventDefault();
    }
    const handleOnChange = address => {
        setIsDelivery(false)
        setErrorMessage(false)
        const data = {
            ...deliveryLocation,
            address: address
        };
        setDeliveryLocation(data);
    };

    const getResultData = (Data, name) => {
        const Result = Data.address_components.filter(
            item => item.types[0] === name
        );
        if (Result.length > 0) {
            return Result[0].long_name;
        }
        return null;
    };

    const getZipCode = async result => {
        zipCode = getResultData(result, "postal_code");
        return result;
    };

    const handleOnSelect = (address, type) => {
        if(address && (address.trim() !== '')) {
            const data = { ...deliveryLocation };
            setDeliveryStatus("");
            setLoadingStatus(true);
            geocodeByAddress(address)
                .then((results) => getZipCode(results[0]))
                .then((results) => getLatLng(results))
                .then((latLng) => {
                    data.address = address;
                    data.lat = latLng.lat;
                    data.lng = latLng.lng;
                    data.zip = zipCode;
                    setDeliveryLocation(data);
                    locationActions.locationSelect(data).then((data) => {
                        setLoadingStatus(false);
                        if (data.status) {
                            setDeliveryStatus(data.status);
                            localStorage.setItem(
                                "deliveryStatus",
                                JSON.stringify(data.status)
                            );
                        }
                    });
                }).catch(()=>{
                setLoadingStatus(false);
                setErrorMessage(true)
            });
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.onChange(true)
        const appClass = document.getElementsByClassName("App")[0];
        if(appClass) {
            appClass.classList.remove("has-blur")
        }

        document.body.classList.remove("no-scroll")
        if(terminalId){localStorage.setItem('terminal_id', JSON.stringify(terminalId))}
        let data = {...deliveryLocation}
        if (deliveryType === 'pickup' && pickupLocations.length > 0) {
            data = {...pickupLocationInfo}
        }
        if (deliveryType === 'curbside' && curbsideLocations.length > 0) {
            data = {...curbsideLocationInfo}
        }
        return dispatch(locationActions.selectedPostLocation(data, history.location.pathname.includes("checkout")))
    };

    const clearSearch = () => {
        const data = { ...deliveryLocation };
        data.address = '';
        setDeliveryLocation(data);
    };

    const handleClickOutside = () => {
        if (!isAddress) {
            const data = { ...deliveryLocation };
            data.address = '';
            setDeliveryLocation(data);
        }
    };
    const handleWrongAddress = () => {
        if (!isAddress) {
            clearSearch()
        }
    };

    const onChangeDeliveryType = (val) => {
        if(val !== deliveryType){
            changeDeliveryType(val)
            handleOnSelect(deliveryLocation.address, val)
        }
    }
    const {modalAddressConfig} = useContext(ThemePramsContext);
    return (
        blog ?
            <StyledModalVerification isShown={isShown} themeProps={modalAddressConfig?.styling}>
                <div className="verification" role="dialog" data-testId="verification-modal">
                    <SVGIcon name={modalAddressConfig.icon1} className="verification__logo" />
                    <div className={`verification__age`}>
                        {modalAddressConfig?.headingText1 && <h3 className="t-shelf-heading verification__form__age-heading">WE GOTTA ASK</h3>}
                        {modalAddressConfig.headingText2}
                        <modalAddressConfig.button className="verification__age__button" onClick={handleSubmit}>
                            {loadingStatus ?
                                <>
                                    &nbsp;
                                    <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                </>
                                :
                                <>
                                    Yes
                                    <span className="t-hidden">Yes button</span>
                                </>
                            }
                        </modalAddressConfig.button>
                        <a href="https://google.com" onClick={ (e) =>  ""} className={`verification__button `}>
                            {loadingStatus ?
                                <>
                                    &nbsp;
                                    <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                </>
                                :
                                "No"
                            }
                        </a>
                    </div>
                </div>
            </StyledModalVerification>
            :
                <StyledModalChangeAddress isShown={isShown} themeProps={modalAddressConfig?.styling}>
                    <div className="verification" role="dialog" data-testId="verification-modal">

                        { storeType === 'rebud' ?
                            <div className="logo-container">
                                {modalAddressConfig.icon1 ?
                                    <SVGIcon name={modalAddressConfig.icon1} className="verification__logo" /> :
                                    <img src={modalAddressConfig.img1} className="verification__logo" alt="logo"/>
                                }
                            </div>
                            :
                                <div className="logo-container"><img src={brandLogo} className="verification__logo" /></div>
                        }
                        { modalAddressConfig.helloText && <h2 className="t-shelf-heading verification__heading">HIGH BUD,<br/>
                            HOW ARE WE SHOPPING?</h2>}

                        <div className="delivery-type-container">
                            {pickupLocations && pickupLocations.length > 0 &&
                            <button
                                type="button"
                                tabIndex="0"
                                onClick={() => onChangeDeliveryType("pickup")}
                                className={`delivery-time ${deliveryType === "pickup" && "active"}`}

                            >
                                  <span className="delivery-details">
                                      <ReactSVG src="/images/icons/pickup-icon.svg" className="icon_theme"/>
                                    <span className={` delivery-details__copy`}>Pickup</span>
                                  </span>
                                <SVGIcon
                                    className="delivery-circle"
                                    name={deliveryType === "pickup" ? modalAddressConfig.icon3 : "delivery-circle"}
                                />
                                <Tooltip>
                                    <h2 className="t-card-sub-heading">In-store</h2>
                                    <p>Curbside Stop by one of our
                                        locations to personally
                                        pick up your order</p>
                                </Tooltip>
                            </button>

                            }
                            {curbsideLocations && curbsideLocations.length > 0 &&

                                <button
                                type="button"
                                tabIndex="0"
                                onClick={() => onChangeDeliveryType("curbside")}
                                className={`delivery-time ${deliveryType === "curbside" && "active"}`}

                                >
                                  <span className="delivery-details">
                                      <ReactSVG src="/images/icons/New-Curbside-Icon.svg" className="icon_theme" width={30} />
                                    <span className={`delivery-details__copy`}>Curbside</span>
                                  </span>
                                    <SVGIcon
                                        className="delivery-circle"
                                        name={deliveryType === "curbside" ? modalAddressConfig.icon3 : "delivery-circle"}
                                    />
                                    <Tooltip>
                                        <h2 className="t-card-sub-heading">Curbside</h2>
                                        <p>Experience the ultimate convenience with Rebud's Curbside Service. Simply pull up
                                            to one of our locations, and our dedicated budtender will hand deliver
                                            your order right to your car, ensuring a seamless, fast and hassle- free experience.</p>
                                    </Tooltip>
                            </button>
                            }
                            <button
                                type="button"
                                tabIndex="0"
                                onClick={() => onChangeDeliveryType("delivery")}
                                className={`delivery-time ${deliveryType === "delivery" && "active"}`}

                            >
                              <span className="delivery-details">
                                  <ReactSVG src="/images/icons/New-Delivery-Icon.svg" className="icon_theme" />
                                <span className={`delivery-details__copy`}>Delivery</span>
                              </span>
                                <SVGIcon
                                    className="delivery-circle"
                                    name={deliveryType === "delivery" ? modalAddressConfig.icon3 : "delivery-circle"}
                                />
                                <Tooltip>
                                    <h2 className="t-card-sub-heading">Delivery</h2>
                                    <p>One of our friendly drivers
                                        will conveniently deliver
                                        your order to you
                                    </p>
                                </Tooltip>

                            </button>



                        </div>
                        <div className="verification__form">
                            {deliveryType === "pickup" && pickupLocations && pickupLocations.length > 0 &&
                                <div className="pickup-store">
                                    <h4>PICKUP STORE
                                    </h4>
                                    <div className="delivery-type-container mod">
                                        {pickupLocations.map(item =>
                                            <button
                                                tabIndex="0"
                                                onClick={(e) => selectPickupLocation(item)}
                                                className={`delivery-time ${item.address === pickupLocationInfo.address && "active"}`}
                                            >
                                            <span className="delivery-details">
                                            <p>{item.address}</p>
                                            </span>
                                                <SVGIcon
                                                    className="delivery-circle"
                                                    name={item.address === pickupLocationInfo.address ? modalAddressConfig.icon3 : "delivery-circle"}
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            }
                            {deliveryType === "curbside" && curbsideLocations && curbsideLocations.length > 0 &&
                                <div className="pickup-store">
                                    <h4>PICKUP STORE
                                    </h4>
                                    <div className="delivery-type-container mod">
                                        {curbsideLocations.map(item =>
                                            <button
                                                tabIndex="0"
                                                onClick={(e) => selectCurbsideLocation(item)}
                                                className={`delivery-time ${item.address === curbsideLocationInfo.address && "active"}`}
                                            >
                                            <span className="delivery-details">
                                            <p>{item.address}</p>
                                            </span>
                                                <SVGIcon
                                                    className="delivery-circle"
                                                    name={item.address === curbsideLocationInfo.address ? modalAddressConfig.icon3 : "delivery-circle"}
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            }
                            {deliveryType === "delivery"  &&

                                <label className="verification__form__label">
                                    {modalAddressConfig.verifyText}

                                    <div className="verification__form__address-input">
                                        <LocationSearchInput
                                            className={`verification__input ${modalAddressConfig.className2} ${isDelivery && deliveryStatus === "notDelivery" ? "not-deliver" :""}`}
                                            address={deliveryLocation.address || ''}
                                            // streetSearch={true}
                                            handleChange={handleOnChange}
                                            handleSelect={handleOnSelect}
                                            // notDeliveryModal={isDelivery && deliveryStatus === "notDelivery" && !verifyModal}
                                            enterZip={true}
                                            errorMessage={errorMessage}
                                            setIsAddress={setIsAddress}
                                            onBlur={handleClickOutside}
                                            handleWrongAddress={handleWrongAddress}
                                            placeholder="Start typing address and select from list"
                                            notDelivery={isDelivery && deliveryStatus === "notDelivery" }
                                        />
                                        {deliveryLocation.address &&
                                            <button  className="verification-delivery_address__clear" onClick={clearSearch}>
                                                <ReactSVG src="/images/icons/clear-modal.svg"/>
                                            </button>
                                        }
                                    </div>
                                </label>
                            }
                            {isKiosk &&
                                <div className="verification__form__ip-address-input">
                                    <div className={`t-sub-${modalAddressConfig.className1} verification__form__label-text`}>Please add POS generated ID</div>
                                    <InputAuthorization
                                        inputName="terminalId"
                                        value={terminalId}
                                        placeholder="ex. ASD12345"
                                        type="text"
                                        className="terminal-id"
                                        onChange={(e) => setTerminalId(e.target.value.trim())}
                                        required={false}
                                    />
                                </div>
                            }


                                <div className={`verification__age ${deliveryType !== 'pickup' && (!deliveryStatus || !deliveryLocation.address.trim()) ? "is-disabled" : ""}`}>
                                    {modalAddressConfig?.headingText1 && <h3 className="t-shelf-heading verification__form__age-heading">WE GOTTA ASK</h3>}
                                    {modalAddressConfig.headingText2}
                                    <div className={modalAddressConfig.className3}>
                                        <modalAddressConfig.button
                                            onClick={handleSubmit}
                                            disabled={deliveryType !== 'pickup' && deliveryType !== 'curbside' && (!deliveryStatus || !deliveryLocation.address.trim() || !isAddress)}>
                                            {loadingStatus ?
                                                <>
                                                    &nbsp;
                                                    <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                                </>
                                                :
                                                <>
                                                    Yes
                                                    <span className="t-hidden">Yes button</span>
                                                </>
                                            }
                                        </modalAddressConfig.button>
                                        <a href="https://google.com" onClick={ (e) => (deliveryType !== 'pickup' && (!deliveryStatus || !deliveryLocation.address.trim())) ? noLinkAction(e) : ""}
                                           className={`verification__button ${deliveryType !== 'pickup' && deliveryType !== 'curbside' && (!deliveryStatus || !deliveryLocation.address.trim()) ? "is-disabled" : ""}`}>
                                            {loadingStatus ?
                                                <>
                                                    &nbsp;
                                                    <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                                </>
                                                :
                                                "No"
                                            }
                                        </a>
                                    </div>
                                </div>
                        </div>
                    </div>

                </StyledModalChangeAddress>
               
    )
};
const mapStateToProps = ({ userAddress, getUnavailableCart, getGiveAway, locationInfo, pickupLocations, curbsideLocations }) => ({
    address: userAddress.address,
    cart: getUnavailableCart.cart,
    giveAway: getGiveAway.giveAway,
    locationPhone: locationInfo.locationPhone,
    pickupLocations: pickupLocations.pickupLocations,
    curbsideLocations: curbsideLocations.curbsideLocations,
});

VerifyModalAddress.propTypes = {
    className: PropTypes.string,
    isShown: PropTypes.bool,
    address: addressPT,
};

export default connect(mapStateToProps)(VerifyModalAddress);
