import React from "react";
import PropTypes from "prop-types";
import StyledFooterMain from "./FooterMainBundle.styled";
import { Link } from "react-router-dom";
import SVGIcon from "../utility/SVGIcon";
import { storeType, storeName } from "../../config/constants";
import LogoLogickit from "../../images/LK_Logo2.png";

const FooterMainBundle = React.memo(({simple, license_number}) => {
  const year = new Date();
  const currentYear = year.getFullYear();

  return (
    <StyledFooterMain simple={simple} data-testid="footerMain">
      <div className="footer-wrap">
        {!simple && storeType === 'rebud' &&
          <SVGIcon name="bundle-icon-footer" className="footer-home__logo" />
        }
  
        <ul className="footer-home__links">
          <li className="footer-home__link">
            <Link to="/contact" className="t-footer">Contact Us</Link>
          </li>
          <li className="footer-home__link">
            <Link to="/privacy" className="t-footer">Privacy Policy</Link>
          </li>
          <li className="footer-home__link">
            <Link to="/terms" className="t-footer">Terms</Link>
          </li>
        </ul>

        <p className="t-body footer-home__copywrite ">Copyright &copy; {currentYear} {storeName}, all rights reserved.</p>
        {license_number &&
          <p className="t-body footer-home__license">Store license number: {license_number}.</p>
        }
        <a href="https://www.logickit.io/" target="_blank" className="t-body footer-home__license d-flex-container">
          Power by &nbsp; <img src={LogoLogickit} height={20} alt="logo"/>
        </a>
      </div>
    </StyledFooterMain>
  );
});

FooterMainBundle.propTypes = {
  simple: PropTypes.bool,
  license_number: PropTypes.string,
};

export default FooterMainBundle;
