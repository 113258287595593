import {
    CHECK_SUMMARY,
    POST_PROMO,
    CHECK_SUMMARY_REQUEST,
    CHECK_SUMMARY_FAIL,
    POST_PROMO_LOADING,
    POST_PROMO_FAIL,
    ORDER_SUCCESS,
    CLEAR_PROMO_ERROR,
    ORDER_FAILED, ORDER_REQUEST, ORDER_PAYTENDER_SUCCESS, ORDER_PAYTENDER_FAILED, ORDER_PAYTENDER_REQUEST
} from '../config/actionTypes';

const initialState = {
    orderSuccess: false,
    loading: false,
}

const initialPaytenderOrderState = {
    orderSuccess: false,
    loading: false,
}


export function summaryCheck(state = {}, action) {
    switch (action.type) {
        case CHECK_SUMMARY:
            return {
                summary: action.summary,
                loading: false
            };
        case CHECK_SUMMARY_REQUEST:
            return {
                summary: [],
                loading: true
            };
        case CHECK_SUMMARY_FAIL:
            return {
                summary: [],
                loading: false
            };
        default:
            return state
    }
}


export function codePromo(state = {}, action) {
    switch (action.type) {
        case POST_PROMO:
            return {
                promo: action.promo,
                promoError: "",
                loading: action.loading,
            };
        case POST_PROMO_LOADING:
            return {
                promo: action.promo,
                promoError: "",
                loading: action.loading,

            };
        case POST_PROMO_FAIL:
            return {
                promo: action.promo,
                promoError: action.promoError,
                loading: action.loading,
            };
        case CLEAR_PROMO_ERROR:
            return {
                promo: "",
                promoError: "",
                loading: false,
            };
        default:
            return state
    }
}
export function postOrder(state = {initialState}, action) {
    switch (action.type) {
        case ORDER_SUCCESS:
            return {
                orderSuccess: true,
                loading: action.loading
            };
        case ORDER_FAILED:
            return {
                orderSuccess: false,
                loading: action.loading
            };
        case ORDER_REQUEST:
            return {
                orderSuccess: false,
                loading: action.loading
            };
        default:
            return state
    }
}

export function postOrderPaytender(state = {initialPaytenderOrderState}, action) {
    switch (action.type) {
        case ORDER_PAYTENDER_SUCCESS:
            return {
                orderSuccess: true,
                loading: action.loading
            };
        case ORDER_PAYTENDER_FAILED:
            return {
                orderSuccess: false,
                loading: action.loading
            };
        case ORDER_PAYTENDER_REQUEST:
            return {
                orderSuccess: false,
                loading: action.loading
            };
        default:
            return state
    }
}
