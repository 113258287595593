import styled from "styled-components";
import {mainFonts} from "../../styles/themes/_baseTheme";

const StyledModalChangeAddress = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform : ${props => props.isShown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity : ${props => props.isShown ? '1' : '0'};
  z-index: 10;
  filter: blur(0) !important;

  .verification {
    margin: ${({themeProps}) => themeProps.margin};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${({themeProps}) => themeProps.maxWidth2};
    min-height: ${({themeProps}) => themeProps.minHeight1};
    background-color: ${({themeProps}) => themeProps.bgColor};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 12;
    border: ${({themeProps}) => themeProps.border};
    background-image: ${({themeProps}) => themeProps.bgImage};
    border-radius: ${({themeProps}) => themeProps.borderRadius};
    overflow: auto;
    max-height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .items-disable{
      margin-top: 10px;
    }
    @media (max-width: 799px) {
      padding: ${({themeProps}) => themeProps.padding1};
      max-width: 360px;
      min-height: ${({themeProps}) => themeProps.minHeight2};
      background-repeat: ${({themeProps}) => themeProps.backgroundRepeat};
    }
    @media (min-width: 800px) {
      padding: ${({themeProps}) => themeProps.padding2};
      max-width: ${({themeProps}) => themeProps.maxWidth3};
    }
    h3{
      font-size: 24px;
      color: ${({theme}) => theme.colors.gray5};
      margin-bottom: 32px;
      width: 100%;
      max-width: ${({themeProps}) => themeProps.maxWidth};
    }
    .verification__form__age-heading {
      margin-bottom: .75rem;
      //color: inherit;
      margin-top: ${({themeProps}) => themeProps.marginBottom3};

      @media (max-width: 799px) {
        margin-bottom: .5rem;
      }
      @media (min-width: 800px) {
        margin-bottom: .75rem;
      }
    }
  }
  .logo-container{
    @media (max-width: 799px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom1};
    }
    @media (min-width: 800px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom2};
    }
  }
.verification__logo {
    align-self: center;
    
    @media (max-width: 799px) {
      width: ${({themeProps}) => themeProps.width1};
      max-height: ${({themeProps}) => themeProps.height1};
    }
    @media (min-width: 800px) {
      margin-top: ${({themeProps}) => themeProps.marginTop3};
      width: ${({themeProps}) => themeProps.width2};
      max-height: ${({themeProps}) => themeProps.height2};
    }
  }
  .verification__heading {
    color: ${({theme}) => theme.colors.brandDark};
    width: ${({themeProps}) => themeProps.maxWidth};;
    max-width: 100%;
    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 1rem;
    }
  }
  .verification__form {
    display: flex;
    flex-direction: column;
    max-width: ${({themeProps}) => themeProps.maxWidth};
    width: 100%;

    @media (max-width: 799px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom3};
      margin-top: ${({themeProps}) => themeProps.marginTop1};
      max-width: ${({themeProps}) => themeProps.maxWidth1};
    }
    @media (min-width: 800px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom4};
    }
  }

  .verification__form__label {
    width: 100%;  
    position: relative;
    color:  ${({themeProps}) => themeProps.color3};
    margin: ${({themeProps}) => themeProps.margin3};
    @media (max-width: 799px) {
      padding-left: ${({themeProps}) => themeProps.paddingLeft};
    }
    .delivery-address_text{
      color: ${({theme}) => theme.colors.gray5};
      padding-bottom: 4px;
      display: block;
    }
    .t-sub-heading.verification__form__label-text{
      text-transform: uppercase;
    }
  }
  

  /* Temporary until v2 design */
  .verification__input {
    background-color: ${({themeProps}) => themeProps.bgColor4};
    color: ${({themeProps}) => themeProps.color7};
    border-radius:  ${({themeProps}) => themeProps.radius};
    border: ${({themeProps}) => themeProps.border3};
    border-bottom: ${({themeProps}) => themeProps.borderBottom2};
    transition: var(--button-transition);
    padding-right: ${({themeProps}) => themeProps.paddingRight};;
    line-height: ${({themeProps}) => themeProps.lineHeight};
    font-family: ${({themeProps}) => themeProps.fontFamily};
    outline: none;
    &:hover,
    &:focus {
      border-color: ${({themeProps}) => themeProps.fontFamily};
    }
    &.not-deliver{
      border-color: ${({theme}) => theme.colors.newErrorForm};
    }
    @media (max-width: 799px) {
      font-size: ${({themeProps}) => themeProps.fontSize3};
      line-height: ${({themeProps}) => themeProps.linHeight2};
    }
    @media (max-width: 859px) {
      padding: ${({themeProps}) => themeProps.padding5};
      width: ${({themeProps}) => themeProps.width7};
      height:${({themeProps}) => themeProps.height};
      font-size: ${({themeProps}) => themeProps.fontSize};
    }

    @media (min-width: 860px) {
      padding: ${({themeProps}) => themeProps.padding4};
      width: ${({themeProps}) => themeProps.width8};
      height: ${({themeProps}) => themeProps.height5};
      border-radius: ${({themeProps}) => themeProps.borderRadius3};
      font-size: ${({themeProps}) => themeProps.fontSize5};
    }
  }

  .verification__form__address-input {
    position: relative;
    @media (max-width: 799px) {
      padding: ${({themeProps}) => themeProps.padding7};
    }
  }
  .verification__form__ip-address-input {
    margin-top: 15px;
    .placeHold{
      display: none;
    }
    .verification__form__label-text{
      color: ${({themeProps}) => themeProps.color5};
    }
    input{
      border-radius: 0;
    }
  }
  .verification-delivery_address__clear {
    border: none;
    outline: none;
    position: absolute;
    right: ${({themeProps}) => themeProps.right};
    top: ${({themeProps}) => themeProps.top};
    width: ${({themeProps}) => themeProps.width9};
    height: ${({themeProps}) => themeProps.height6};
    align-self: ${({themeProps}) => themeProps.alignSelf};
    svg {
      fill: ${({themeProps}) => themeProps.fill};
      width: ${({themeProps}) => themeProps.width4};
      height: ${({themeProps}) => themeProps.height4};

    }
    @media (max-width: 799px) {
      top: ${({themeProps}) => themeProps.top3};
      right: ${({themeProps}) => themeProps.right3};

      svg {
        fill: ${({themeProps}) => themeProps.fill2};
        width: ${({themeProps}) => themeProps.width10};
        height: ${({themeProps}) => themeProps.height7};
      }
    }
    @media (min-width: 800px) {
      width: ${({themeProps}) => themeProps.width11};
      height: ${({themeProps}) => themeProps.height8};
      margin-bottom: ${({themeProps}) => themeProps.marginBottom8};
      font-size: ${({themeProps}) => themeProps.fontSize9};
    }
    
    
    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
    @media (max-width: 799px) {
      top: ${({themeProps}) => themeProps.top2};
    }
  }
  .delivery-type-container{
    display: flex;
    flex-wrap: wrap;
    gap: 16px 35px;
    justify-content: center;
    margin-bottom: 28px;
    width: 100%;
    max-width: ${({themeProps}) => themeProps.maxWidth};
    &.one-deliver-container{
      justify-content: right;
    }
    &.mod{
      display: grid;
      grid-template-columns: auto;
      grid-row-gap: 16px;
      .delivery-time{
        max-width: 100%;
        width: 100%;
      }
    }
    .delivery-details {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
    }
    .delivery-details__copy {
      margin-left: 16px;
      padding-right: 4px;
    }
    .delivery-time {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      cursor: pointer;
      transition: var(--button-transition);
      outline: none;
      position: relative;
      padding: 5px 16px;
      color:${({theme}) => theme.colors.gray5};
      max-width: 236px;
      width: 236px;

      &:hover,
      &:focus,
      &.active {
        border: 1px solid ${({theme}) => theme.colors.brandDark};
      }
    }
    .delivery-circle {
      width: 26px;
      height: 26px;
      stroke: ${({themeProps}) => themeProps.color10};
      align-self: center;
    }
  }
  .update_button {
    width: 100%;
    color: #fff;
    margin-top: 40px;
    background-color: ${({themeProps}) => themeProps.color12};
    &:disabled{
      background-color: #ffffff;
      color: #757575;
    }
    :hover{
      color: ${({themeProps}) => themeProps.color13};
    }
  }
  .bg-text {
    padding-top: .125rem;
    padding-bottom: .125rem;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 10px;
    margin-top: 13px;
    .svg-right,
    .svg-left {
      display: block;
      width: 13px;
      fill: ${({theme}) => theme.colors.highlight};
    }
    .svg-right {
      width: 12px;
    }

    .bg-text-main {
      background-color: ${({theme}) => theme.colors.highlight};
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    .warning-icon{
      padding-right: 8px;
    }
  }
  .pickup-store{
    h4{
      font-size: 18px;
      margin-bottom: 8px;
    }
  }
  .simple-modal__button {
    margin-bottom: .5rem;
    padding: 8px;
    display: flex;
    margin-left: auto;
    fill: ${({theme}) => theme.colors.gray3};
    color: ${(props) => props.color2};
    outline: none;

    &:hover,
    &:focus {
      fill: ${({theme}) => theme.colors.brandDark};
    }
  }

  .simple-modal__close {
    height: 20px;
    width: 20px;
    fill: inherit;
  }
  .icon_theme{
    path{
      fill: ${({theme}) => theme.colors.brandDark};
    }
  }
  p, h4{
    color: ${({theme}) => theme.colors.gray5};
  }
    .verification__age {
    width: 100%;
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    padding-bottom: ${({themeProps}) => themeProps.paddingBottom1};
    
    &.is-disabled {
      color: ${({themeProps}) => themeProps.color};
    }
  }
  
  // Button / Link overwrite, change with future button and link design cleanup
  .verification__button,
  .verification__age__button {
    @media (max-width: 799px) {
      width: ${({themeProps}) => themeProps.width5};
      min-height: ${({themeProps}) => themeProps.minHeight3};
    }
    @media (min-width: 800px) {
      width: ${({themeProps}) => themeProps.width6};
      min-height: ${({themeProps}) => themeProps.minHeight4};
    }
  }
  .verification__yes__button {
    margin-right: 18.28px;
    padding: 8px 31px;
    width: 141.77px;
    color: ${({ theme }) => theme.colors.gray4};
    @media (max-width: 799px) {
      margin-right: 15.88px;
      font-size: var(--type-scale-14);
      width: 111.95px;
      padding: 8px 31px;
    }
  }
  // Button / Link overwrite, change with future button and link design cleanup
  .verification__button {
    padding: ${({themeProps}) => themeProps.padding3};
    line-height: normal;
    display: inline-block;
    border: ${({themeProps}) => themeProps.border2};
    background-color: ${({themeProps}) => themeProps.bgColor1};
    border-color: ${({themeProps}) => themeProps.borderColor1};
    color:${({themeProps}) => themeProps.color1};
    font-size: ${({themeProps}) => themeProps.fontSize1};
    font-weight: var(--weight-bold);
    font-family: ${({themeProps}) => themeProps.fontFamily5};
    text-align: center;
    flex-shrink: 0;
    transition: var(--button-transition);
    text-decoration: none;
    width: ${({themeProps}) => themeProps.width};
    opacity: 1;
    border-radius: ${({themeProps}) => themeProps.borderRadius2};
    margin-left: ${({themeProps}) => themeProps.marginLeft2};

    &:hover,
    &:focus {
      background-color: ${({themeProps}) => themeProps.bgColor2};
      border-color: ${({themeProps}) => themeProps.borderColor2};
      color: ${({themeProps}) => themeProps.color6};
    }

    &.is-disabled {
      background-color: ${({themeProps}) => themeProps.bgColor3};
      border-color: ${({themeProps}) => themeProps.borderColor3};
      color: ${({themeProps}) => themeProps.color2};
      cursor: default;
    }

    @media (max-width: 799px) {
      margin-top: ${({themeProps}) => themeProps.marginTop};
      margin-left: ${({themeProps}) => themeProps.marginLeft3};
      font-size: ${({themeProps}) => themeProps.fontSize2};
      width: ${({themeProps}) => themeProps.width3};
      padding: ${({themeProps}) => themeProps.padding};
    }
    @media (min-width: 800px) {
      margin-left: ${({themeProps}) => themeProps.marginLeft};
    }
  }
  .buttons-container {
    @media (max-width: 799px) {
      display: flex;
      justify-content: center;
    }
    @media (min-width: 800px) {
      display: block;
    }
  }
  .verification__form__age-copy {
    color: ${({themeProps}) => themeProps.color4};
    font-weight: ${({themeProps}) => themeProps.fontWeight};
    font-family: ${({themeProps}) => themeProps.fontFamily1};

    @media (max-width: 799px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom5};
      font-size:${({themeProps}) => themeProps.fontSize7};
      padding: ${({themeProps}) => themeProps.padding6};
    }
    @media (min-width: 800px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom6};
      font-size:${({themeProps}) => themeProps.fontSize8};
    }
  }
`;

export default StyledModalChangeAddress;
