/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import StyledModalChangeAddress from "./ModalChangeAddress.styled";
import LocationSearchInput from "../LocationSearchInput/LocationSearchInput";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {cartActions, checkout, locationActions} from '../../actions/index';
import { connect } from "react-redux";
import LoadingEllipsis from "../LoadingEllipsis/LoadingEllipsis";
import SVGIcon from "../utility/SVGIcon";
import {useWindowSize} from "../../hooks/useWindowSize";
import { addressPT } from "../../config/prop-types";
import {ThemePramsContext} from "../../App";
import ButtonOutline from "../ui/ButtonOutline";
import {ReactSVG} from "react-svg";
import CartItem from "./CartItem/CartItem";
import {history} from "../../helpers";
import {isKiosk, storeType} from "../../config/constants";
import InputAuthorization from "../forms/InputAuthorization/InputAuthorization";
import StyledModalVerification from "../ModalVerification/ModalVerification.styled";
import Tooltip from "../Tooltip/Tooltip";

let zipCode = null;

const ModalChangeAddress = props => {
    const { dispatch, address, isShown, cart, locationPhone, onChange} = props;
    const { width } = useWindowSize();

    const [deliveryLocation, setDeliveryLocation] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        delivery_type: 'delivery'
    });
    const [pickupLocationInfo, setPickupLocationInfo] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: "",
        delivery_type: 'is_pickup'
    });

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [isAddress, setIsAddress] = useState(true);
    const [deliveryType, changeDeliveryType] = useState("delivery");
    const [locationInfo, setLocationInfo] = useState('');
    const isCalledRef = React.useRef(false);

    useEffect(() => {
        if(address && locationInfo && Object.keys(locationInfo).length && !isCalledRef.current){
            isCalledRef.current = true;
            // changeDeliveryType(address.is_pickup && locationInfo.is_store_pickup ? "pickup" : "delivery")
            if(address.delivery_type === "is_curbside" && locationInfo.is_curbside){
                changeDeliveryType( "curbside")
            } else if(address.delivery_type === "is_pickup" && locationInfo.is_store_pickup){
                changeDeliveryType("pickup")
            }
        }
    },[address, locationInfo])
    useEffect(() => {
        if(isShown){
            document.body.classList.add("no-scroll");
            const appContainer = document.querySelector(".App")
            if(appContainer) {
                appContainer.classList.add("has-blur");
            }
        }
    }, []);

    useEffect(() => {
        if(width <= 768 && isShown) {
            document.body.classList.add("no-scroll");
        } else if(width > 768 && isShown) {
            document.body.classList.add("has-scroll");
        }
        return () => {
            document.body.classList.remove("no-scroll");
        }
    }, [width, isShown]);

    useEffect(() => {
        if(deliveryStatus === "notDelivery") {
            setIsDelivery(true)
        } else {
            setIsDelivery(false)
        }
    }, [deliveryStatus]);
    useEffect(() => {
        if(locationPhone){
            setLocationInfo(locationPhone)
        }
    }, [locationPhone])
    useEffect(() => {
        dispatch(locationActions.selectedLocation());
    }, []);
    const getCart = (data, type) => {
        let data1 = {...deliveryLocation}
        if((deliveryType !== 'delivery' && type !== 'delivery')){
            if (locationInfo && locationInfo.geolocations && locationInfo.geolocations[0]) {
                data1 = {...pickupLocationInfo}
            }
        }
        isShown && dispatch(cartActions.getUnavailabilityCart((deliveryType !== 'delivery' && type !== 'delivery') ? data1 : data));
    }

    useEffect(() => {
        if(address){
            const data = {
                ...deliveryLocation,
                address: address.address,
                lat: address.lat,
                lng: address.lng,
                zip: address.zip,
            };
            setDeliveryLocation(data);
            handleOnSelect(address.address)
        }
    }, [ address, isShown ]);
    useEffect(() => {
        if(locationInfo && locationInfo.geolocations){
            const data = {
                ...pickupLocationInfo,
                address: locationInfo.geolocations[0]?.address,
                lat: locationInfo.geolocations[0]?.lat,
                lng: locationInfo.geolocations[0]?.lon,
                zip:locationInfo.geolocations[0]?.zip,
                city:locationInfo.geolocations[0]?.city,
                state:locationInfo.geolocations[0]?.state,
            };
            setPickupLocationInfo(data);
            // setCurbsideLocationInfo(data);
        }

    }, [ locationInfo ]);

    const handleOnChange = address => {
        setIsDelivery(false)
        setErrorMessage(false)
        const data = {
            ...deliveryLocation,
            address: address
        };
        setDeliveryLocation(data);
    };

    const getResultData = (Data, name) => {
        const Result = Data.address_components.filter(
            item => item.types[0] === name
        );
        if (Result.length > 0) {
            return Result[0].long_name;
        }
        return null;
    };

    const getZipCode = async result => {
        zipCode = getResultData(result, "postal_code");
        return result;
    };

    const handleOnSelect = (address, type) => {
        if(address && (address.trim() !== '')) {
            const data = { ...deliveryLocation };
            setDeliveryStatus("");
            setLoadingStatus(true);
            geocodeByAddress(address)
                .then((results) => getZipCode(results[0]))
                .then((results) => getLatLng(results))
                .then((latLng) => {
                    data.address = address;
                    data.lat = latLng.lat;
                    data.lng = latLng.lng;
                    data.zip = zipCode;
                    setDeliveryLocation(data);
                     getCart(data, type);
                    locationActions.locationSelect(data).then((data) => {
                        setLoadingStatus(false);
                        if (data.status) {
                            setDeliveryStatus(data.status);
                            localStorage.setItem(
                                "deliveryStatus",
                                JSON.stringify(data.status)
                            );
                        }
                    });
                }).catch(()=>{
                setLoadingStatus(false);
                setErrorMessage(true)
            });
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.onChange(true)
        const appClass = document.getElementsByClassName("App")[0];
        if(appClass) {
            appClass.classList.remove("has-blur")
        }

        document.body.classList.remove("no-scroll")
        let data = {...deliveryLocation}
        if ((deliveryType === 'pickup' || deliveryType === 'curbside') && locationInfo && locationInfo.geolocations && locationInfo.geolocations[0]) {
            data = {...pickupLocationInfo, delivery_type: deliveryType === 'curbside' ? 'is_curbside' : 'is_pickup'}
        }

        return dispatch(locationActions.selectedPostLocation(data, history.location.pathname.includes("checkout")))
    };

    const clearSearch = () => {
        const data = { ...deliveryLocation };
        data.address = '';
        setDeliveryLocation(data);
    };

    const handleClickOutside = () => {
        if (!isAddress) {
            const data = { ...deliveryLocation };
            data.address = '';
            setDeliveryLocation(data);
        }
    };
    const handleWrongAddress = () => {
        if (!isAddress) {
            clearSearch()
        }
    };

    const onChangeDeliveryType = (val) => {
        if(val !== deliveryType){
            changeDeliveryType(val)
            // handleOnSelect(address.address, val)
            handleOnSelect(deliveryLocation.address, val)
        }

        // getCart('', val)
    }
    useEffect(()=>{
        if(cart){
            setLocationInfo(cart.is_store_pickup)
        }
    })
    const {modalAddressConfig} = useContext(ThemePramsContext);
    const toggleShown = () => onChange ? onChange(false) :'';
    return (
                <StyledModalChangeAddress isShown={isShown} themeProps={modalAddressConfig?.styling}>
                    <div className="verification" role="dialog" data-testId="verification-modal">
                        {onChange &&
                            <button onClick={toggleShown} className="simple-modal__button">
                                <SVGIcon name="close" className="simple-modal__close" />
                                <span className="t-hidden">Close modal</span>
                            </button>
                        }
                        <h3>CHANGE DELIVERY LOCATION</h3>
                        <div className={`delivery-type-container ${!locationInfo?.is_store_pickup && !locationInfo?.is_curbside ? 'one-deliver-container' :''}`}>
                            {locationInfo?.is_store_pickup &&
                                <button
                                    type="button"
                                    tabIndex="0"
                                    onClick={() => onChangeDeliveryType("pickup")}
                                    className={`delivery-time ${deliveryType === "pickup" && "active"}`}

                                >
                                  <span className="delivery-details">
                                      <ReactSVG src="/images/icons/pickup-icon.svg" className="icon_theme"/>
                                    <span className={` delivery-details__copy`}>Pickup</span>
                                  </span>
                                    <SVGIcon
                                        className="delivery-circle"
                                        name={deliveryType === "pickup" ? modalAddressConfig.icon3 : "delivery-circle"}
                                    />
                                    <Tooltip>
                                        <h2 className="t-card-sub-heading">In-store</h2>
                                        <p>Curbside Stop by one of our
                                            locations to personally
                                            pick up your order</p>
                                    </Tooltip>
                                </button>

                            }
                            {locationInfo?.is_curbside &&
                                <button
                                type="button"
                                tabIndex="0"
                                onClick={() => onChangeDeliveryType("curbside")}
                                className={`delivery-time ${deliveryType === "curbside" && "active"}`}

                            >
                              <span className="delivery-details">
                                  <ReactSVG src="/images/icons/New-Curbside-Icon.svg" className="icon_theme" width={30} />
                                <span className={`delivery-details__copy`}>Curbside</span>
                              </span>
                                <SVGIcon
                                    className="delivery-circle"
                                    name={deliveryType === "curbside" ? modalAddressConfig.icon3 : "delivery-circle"}
                                />
                                <Tooltip>
                                    <h2 className="t-card-sub-heading">Curbside</h2>
                                    <p>Experience the ultimate convenience with Rebud's Curbside Service. Simply pull up
                                        to one of our locations, and our dedicated budtender will hand deliver
                                        your order right to your car, ensuring a seamless, fast and hassle- free experience.</p>
                                </Tooltip>
                            </button>
                            }
                            <button
                                type="button"
                                tabIndex="0"
                                onClick={() => onChangeDeliveryType("delivery")}
                                className={`delivery-time ${deliveryType === "delivery" && "active"}`}

                            >
                              <span className="delivery-details">
                                  <ReactSVG src="/images/icons/New-Delivery-Icon.svg" className="icon_theme"/>
                                <span className={`delivery-details__copy`}>Delivery</span>
                              </span>
                                <SVGIcon
                                    className="delivery-circle"
                                    name={deliveryType === "delivery" ? modalAddressConfig.icon3 : "delivery-circle"}
                                />
                                <Tooltip>
                                    <h2 className="t-card-sub-heading">Delivery</h2>
                                    <p>One of our friendly drivers
                                        will conveniently deliver
                                        your order to you
                                    </p>
                                </Tooltip>
                            </button>


                        </div>
                        <div className="verification__form">
                            {deliveryType === "pickup" && locationInfo?.is_store_pickup &&
                                <div className="pickup-store">
                                    <h4>PICKUP STORE</h4>
                                    <p>{locationInfo && locationInfo.geolocations && locationInfo.geolocations[0] && locationInfo.geolocations[0].address}</p>
                                </div>
                            }
                            {deliveryType === "curbside" && locationInfo?.is_curbside &&
                                <div className="pickup-store">
                                    <h4>PICKUP STORE</h4>
                                    <p>{locationInfo && locationInfo.geolocations && locationInfo.geolocations[0] && locationInfo.geolocations[0].address}</p>
                                </div>
                            }
                            {deliveryType === "delivery" &&
                                <label className="verification__form__label">
                                    <span className="delivery-address_text">Delivery Address</span>
                                    <div className="verification__form__address-input">
                                        <LocationSearchInput
                                            className={`verification__input ${modalAddressConfig.className2} ${isDelivery && deliveryStatus === "notDelivery" ? "not-deliver" :""}`}
                                            address={deliveryLocation.address || ''}
                                            // streetSearch={true}
                                            handleChange={handleOnChange}
                                            handleSelect={handleOnSelect}
                                            notDeliveryModal={isDelivery && deliveryStatus === "notDelivery" }
                                            enterZip={true}
                                            errorMessage={errorMessage}
                                            setIsAddress={setIsAddress}
                                            onBlur={handleClickOutside}
                                            handleWrongAddress={handleWrongAddress}
                                            placeholder="Start typing address and select from list"
                                            notDelivery={isDelivery && deliveryStatus === "notDelivery"}
                                        />
                                        {deliveryLocation.address &&
                                            <button  className="verification-delivery_address__clear" onClick={clearSearch}>
                                                <ReactSVG src="/images/icons/clear-modal.svg"/>
                                            </button>
                                        }
                                    </div>
                                </label>
                            }

                            {isDelivery && deliveryStatus === "notDelivery"  &&
                                <div>
                                    <div className="bg-text">
                                        <SVGIcon className="svg-right" name="filter-svg-left" />
                                        <p className="bg-text-main">
                                            <ReactSVG  src="/images/icons/warning-icon.svg" className="warning-icon"/>
                                            Sorry, We Don’t Deliver There Yet</p>
                                        <SVGIcon className="svg-left" name="filter-svg-right" />

                                    </div>
                                    <p>
                                        Feel free to search to for a different
                                        address or you can keep your current  delivery location
                                    </p>
                                </div>
                            }

                                <div>
                                    {cart && cart.cartItems && cart.cartItems.length > 0 && deliveryStatus !== "notDelivery" &&
                                        <>
                                            <div className="bg-text">
                                                <SVGIcon className="svg-right" name="filter-svg-left" />
                                                <p className="bg-text-main">
                                                    <ReactSVG  src="/images/icons/warning-icon.svg" className="warning-icon"/>
                                                    Unavailable Items at New Location</p>
                                                <SVGIcon className="svg-left" name="filter-svg-right" />
                                            </div>
                                            <ul className="list">

                                                {cart.cartItems.map(item =>
                                                    <CartItem
                                                        key={item.id}
                                                        name={item.product && item.product.product_catalogue && item.product.product_catalogue.name}
                                                        category={item.product && item.product.categories}
                                                        netWeight={item.product && item.product.product_catalogue && item.product.product_catalogue.net_weight}
                                                        netWeight_g={item.product && item.product.product_catalogue && item.product.product_catalogue.net_weight_g}
                                                        imgUrl={item.product && item.product.product_catalogue && item.product.product_catalogue?.pictures[0]}
                                                    />
                                                )}

                                            </ul>
                                            <p className="items-disable">
                                                Items will be removed from your bag upon update
                                            </p>
                                        </>}

                                    {history.location.pathname.includes("checkout") &&
                                        <>
                                            <div className="bg-text">
                                                <SVGIcon className="svg-right" name="filter-svg-left" />
                                                <p className="bg-text-main">
                                                    Head Back to Your Bag?</p>
                                                <SVGIcon className="svg-left" name="filter-svg-right" />
                                            </div>
                                            <p>By making this update you’ll go back to review your new order details</p>
                                        </>
                                    }


                                </div>

                                <ButtonOutline className="update_button" onClick={handleSubmit} disabled={!deliveryStatus || deliveryStatus === "notDelivery" || !deliveryLocation.address.trim() || !isAddress}>
                                    {loadingStatus ?
                                        <>
                                            &nbsp;
                                            <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                        </>
                                        :
                                        <>
                                            UPDATE
                                            <span className="t-hidden">Update button</span>
                                        </>
                                    }
                                </ButtonOutline>
                        </div>
                    </div>
                </StyledModalChangeAddress>
               
    )
};
const mapStateToProps = ({ userAddress, getUnavailableCart, getGiveAway, locationInfo }) => ({
    address: userAddress.address,
    cart: getUnavailableCart.cart,
    giveAway: getGiveAway.giveAway,
    locationPhone: locationInfo.locationPhone,
});

ModalChangeAddress.propTypes = {
    className: PropTypes.string,
    isShown: PropTypes.bool,
    address: addressPT,
};

export default connect(mapStateToProps)(ModalChangeAddress);
