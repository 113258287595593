/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import StyledModalVerification from "./ModalVerification.styled";
import LocationSearchInput from "../LocationSearchInput/LocationSearchInput";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { locationActions } from '../../actions/index';
import { connect } from "react-redux";
import LoadingEllipsis from "../LoadingEllipsis/LoadingEllipsis";
import SVGIcon from "../utility/SVGIcon";
import InputAuthorization from "../forms/InputAuthorization/InputAuthorization";
import {isKiosk, storeType} from "../../config/constants";
import {useWindowSize} from "../../hooks/useWindowSize";
import { addressPT } from "../../config/prop-types";
import {ThemePramsContext} from "../../App";
// TODO: Dynamically add Brand Name + Brand Logo
// TODO: zip input only needs pattern attribute pattern if local = en
// TODO: Get links for "Terms and Conditions of Use" and "PrivacyDeathRow &amp; cookies"
// TODO: Build form input component
let zipCode = null;

const ModalVerification = props => {
    const { dispatch, address, isShown, brandLogo} = props;
    const { width } = useWindowSize();
    const [deliveryLocation, setDeliveryLocation] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: ""
    });
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [terminalId, setTerminalId] = useState('');
    const [isAddress, setIsAddress] = useState(false);
    const [blog, setBlog] = useState(false)

    useEffect(() => {
        if(isShown){
            document.body.classList.add("no-scroll");
            const appContainer = document.querySelector(".App")
            if(appContainer) {
                appContainer.classList.add("has-blur");
            }
        }
    }, []);

    useEffect(() => {
        if(width <= 768 && isShown) {
            document.body.classList.add("no-scroll");
        } else if(width > 768 && isShown) {
            document.body.classList.add("has-scroll");
        }
        return () => {
            document.body.classList.remove("no-scroll");
        }
    }, [width, isShown]);

    useEffect(() => {
        if(deliveryStatus === "notDelivery") {
            setIsDelivery(true)
        } else {
            setIsDelivery(false)
        }
    }, [deliveryStatus]);

    useEffect(() => {
        dispatch(locationActions.selectedLocation());
    }, []);

    useEffect(() => {
        if(address){
            const data = {
                ...deliveryLocation,
                address: address.address,
                lat: address.lat,
                lng: address.lng,
                zip: address.zip,
            };
            setDeliveryLocation(data);
            handleOnSelect(address.address)
        }
    }, [ address ]);

    const handleOnChange = address => {
        setIsDelivery(false)
        setErrorMessage(false)
        const data = {
            ...deliveryLocation,
            address: address
        };
        setDeliveryLocation(data);
    };

    const getResultData = (Data, name) => {
        const Result = Data.address_components.filter(
            item => item.types[0] === name
        );
        if (Result.length > 0) {
            return Result[0].long_name;
        }
        return null;
    };

    const getZipCode = async result => {
        zipCode = getResultData(result, "postal_code");
        return result;
    };

    const handleOnSelect = (address) => {
        if(address && (address.trim() !== '')) {
            const data = { ...deliveryLocation };
            setDeliveryStatus("");
            setLoadingStatus(true);
            geocodeByAddress(address)
                .then((results) => getZipCode(results[0]))
                .then((results) => getLatLng(results))
                .then((latLng) => {
                    data.address = address;
                    data.lat = latLng.lat;
                    data.lng = latLng.lng;
                    data.zip = zipCode;
                    setDeliveryLocation(data);
                    locationActions.locationSelect(data).then((data) => {
                        setLoadingStatus(false);
                        if (data.status) {
                            setDeliveryStatus(data.status);
                            localStorage.setItem(
                                "deliveryStatus",
                                JSON.stringify(data.status)
                            );
                        }
                    });
                }).catch(()=>{
                setLoadingStatus(false);
                setErrorMessage(true)
            });
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.onChange(true)
        const appClass = document.getElementsByClassName("App")[0];
        if(appClass) {
            appClass.classList.remove("has-blur")
        }

        document.body.classList.remove("no-scroll")
        if(terminalId){localStorage.setItem('terminal_id', JSON.stringify(terminalId))}
        if(blog){
            return
        }else
            return dispatch(locationActions.selectedPostLocation(deliveryLocation))
    };

    const clearSearch = () =>{
        const data = { ...deliveryLocation };
        data.address = '';
        setDeliveryLocation(data);
    };

    const noLinkAction = (e) => {
        e.preventDefault();
    }
    
    useEffect(() => {
        if(window.location.pathname == '/blog'){
            setBlog(true)
        }
    }, []);

    const handleClickOutside = () => {
        if (!isAddress) {
            const data = { ...deliveryLocation };
            data.address = '';
            setDeliveryLocation(data);
        }
    };
    const handleWrongAddress = () => {
        if (!isAddress) {
            clearSearch()
        }
    };
    const {modalAddressConfig} = useContext(ThemePramsContext);
    return (
        blog ?
                <StyledModalVerification isShown={isShown} themeProps={modalAddressConfig?.styling}>
                    <div className="verification" role="dialog" data-testId="verification-modal">
                        <SVGIcon name={modalAddressConfig.icon1} className="verification__logo" />
                        <div className={`verification__age`}>
                            {modalAddressConfig?.headingText1 && <h3 className="t-shelf-heading verification__form__age-heading">WE GOTTA ASK</h3>}
                            {modalAddressConfig.headingText2}
                            <modalAddressConfig.button className="verification__age__button" onClick={handleSubmit}>
                                {loadingStatus ?
                                    <>
                                        &nbsp;
                                        <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                    </>
                                    :
                                    <>
                                    Yes
                                    <span className="t-hidden">Yes button</span>
                                    </>
                                }
                            </modalAddressConfig.button>
                            <a href="https://google.com" onClick={ (e) =>  ""} className={`verification__button `}>
                                {loadingStatus ?
                                    <>
                                        &nbsp;
                                        <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                    </>
                                    :
                                    "No"
                                }
                            </a>
                        </div>
                    </div>
                </StyledModalVerification>   
            :
                <StyledModalVerification isShown={isShown} themeProps={modalAddressConfig?.styling}>
                    <div className="verification" role="dialog" data-testId="verification-modal">
                        {storeType === 'rebud' ?
                            <>
                                {modalAddressConfig.icon1 ?
                                    <SVGIcon name={modalAddressConfig.icon1} className="verification__logo" /> :
                                    <img src={modalAddressConfig.img1} className="verification__logo" alt="logo"/>
                                }
                            </>
                            :
                            <img src={brandLogo} className="verification__logo" />
                        }

                        <div className="verification__form">
                            {modalAddressConfig.helloText && <h2 className="t-shelf-heading verification__heading">HIGH BUD,</h2>}
                            <label className="verification__form__label">
                                {modalAddressConfig.verifyText}
                                <div className="verification__form__address-input">
                                    <LocationSearchInput
                                        className={`verification__input ${modalAddressConfig.className2}`}
                                        address={deliveryLocation.address || ''}
                                        handleChange={handleOnChange}
                                        handleSelect={handleOnSelect}
                                        notDelivery={isDelivery && deliveryStatus === "notDelivery"}
                                        enterZip={true}
                                        errorMessage={errorMessage}
                                        setIsAddress={setIsAddress}
                                        onBlur={handleClickOutside}
                                        handleWrongAddress={handleWrongAddress}
                                        placeholder={modalAddressConfig.placeholder}
                                    />
                                    {deliveryLocation.address &&
                                    <button  className="verification-delivery_address__clear" onClick={clearSearch}>
                                        <SVGIcon name={modalAddressConfig.icon2} width="16" />
                                        <span className="t-hidden">clear search</span>
                                    </button>
                                    }
                                </div>
                            </label>
                            {isKiosk &&
                            <div className="verification__form__ip-address-input">
                                <div className={`t-sub-${modalAddressConfig.className1} verification__form__label-text`}>Please add POS generated ID</div>
                                <InputAuthorization
                                    inputName="terminalId"
                                    value={terminalId}
                                    placeholder="ex. ASD12345"
                                    type="text"
                                    className="terminal-id"
                                    onChange={(e) => setTerminalId(e.target.value.trim())}
                                    required={false}
                                />
                            </div>
                            }
                        </div>

                        <div className={`verification__age ${!deliveryStatus || !deliveryLocation.address.trim() ? "is-disabled" : ""}`}>
                            {modalAddressConfig?.headingText1 && <h3 className="t-shelf-heading verification__form__age-heading">WE GOTTA ASK</h3>}
                            {modalAddressConfig.headingText2}
                            <div className={modalAddressConfig.className3}>
                            <modalAddressConfig.button onClick={handleSubmit} disabled={!deliveryStatus || !deliveryLocation.address.trim() || !isAddress}>
                                {loadingStatus ?
                                    <>
                                        &nbsp;
                                        <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                    </>
                                    :
                                    <>
                                    Yes
                                    <span className="t-hidden">Yes button</span>
                                    </>
                                }
                            </modalAddressConfig.button>
                            <a href="https://google.com" onClick={ (e) => (!deliveryStatus || !deliveryLocation.address.trim()) ? noLinkAction(e) : ""} className={`verification__button ${!deliveryStatus || !deliveryLocation.address.trim() ? "is-disabled" : ""}`}>
                                {loadingStatus ?
                                    <>
                                        &nbsp;
                                        <LoadingEllipsis color={modalAddressConfig.color1} size=".3rem" />
                                    </>
                                    :
                                    "No"
                                }
                            </a>
                            </div>
                        </div>
                    </div>
                </StyledModalVerification>
               
    )
};
const mapStateToProps = ({ userAddress }) => ({
    address: userAddress.address
});

ModalVerification.propTypes = {
    className: PropTypes.string,
    isShown: PropTypes.bool,
    address: addressPT,
};

export default connect(mapStateToProps)(ModalVerification);
