import bundleTheme from '../../styles/themes/BundleTheme';
import rebudTheme from "../../styles/themes/DefaultTheme";
import {FaChevronDown} from "react-icons/fa";
import React from "react";
import ButtonOutline from "../../components/ui/ButtonOutline";
import HeaderDeliveryInputBundle from "../../components/HeaderDeliveryInputBundle/HeaderDeliveryInputBundle";
import MainMenu from "../../components/MainMenu/MainMenu";
import MobileMenuBundle from "../../components/MobileMenuBundle/MobileMenuBundle";
import ButtonOutLineBundle from "../../components/ui/ButtonOutLineBundle";
import Button from "../../components/ui/Button";
import ButtonSecondary from "../../components/ui/ButtonSecondary";
import ButtonPrimary from "../../components/ui/ButtonPrimary";
import enjoyableTheme from "../../styles/themes/EnjoyableTheme";

const configBundle = {
    contactUsConfig: {
        background: bundleTheme.colors.bundleBlack,
        colorFonts: bundleTheme.colors.bundleWhite,
        classMainFont: 't-font-DINNextLTPro-Regular',
        classNameTitleFont: 't-font-DINNextLTPro-Regular',
        classNameDescription: 't-font-DINNextLTPro-Regular',
        color: 'bundleBlack',
        pstText: 'PST (toll free)',
        mainPageHeadClass1: 't-section-title',
        mainPageHeadClass2: 't-link',
        mainPageHeadClass3: '',
        mainPageHeadClass4: '',
        contactFormFont: 'DINNextLTPro-Regular, sans-serif',
        contactFormClass: 't-section-heading t-font-DINNextLTPro-Regular',
        buttonFont: 'DINNextLTPro-Regular, sans-serif',
        buttonColor: "#181818",
    },
    checkoutConfig: {
        headingClass: 't-section-heading t-font-DINNextLTPro-Regular',
        classNameFont1: 't-font-DINNextLTPro-Regular',
        fontName1:  '',
        iconName1:  'checked-bundle',
        iconName2:  'check-bundle',
        steps: {
            font1: '',
            font2: 't-subtitle-large t-font-DINNextLTPro-Regular',
            font3: '',
            font4: '',
            font5: '',
            font6: '',
            font7: '',
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.bundleGrey,
            color3: bundleTheme.colors.bundleBlack,
            color4: bundleTheme.colors.bundleGrey,
            classNameFont1: 't-font-DINNextLTPro-Regular',
        },
        simpleModal: {
            color1: bundleTheme.colors.gray4,
            color2: bundleTheme.colors.bundleBlack
        },
        mainFont1: 't-title-small',
        mainFont2: '',
        mainFont3: '',
        mainFont4: '',
        iconName3: 'delivery-circle-active-bundle',
        mainFont5: '',
        iconName4: 'delivery-normal-box-bundle',
        mainFont6: '',
        dark: true,
        mainFont7: '',
        mainFont8: '',
        mainFont9: 't-font-lato-regular',
        mainText1: 'Bundle',
        inputAuth1: {
            font: 't-font-DINNextLTPro-Regular',
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.bundleWhite,
            color3: bundleTheme.colors.bundleGrey,
            color4: bundleTheme.colors.bundleWhite,
            color5: bundleTheme.colors.bundleWhite,
            color6: bundleTheme.colors.bundleWhite,
            color7: bundleTheme.colors.bundleBlack,
            color8: bundleTheme.colors.bundleBlack,
            error:  bundleTheme.colors.error,
            color9: bundleTheme.colors.bundleGrey,
            color10: bundleTheme.colors.bundleWhite,
            color11: bundleTheme.colors.bundleBlack,

        },
        dropUpload: {
            color1: bundleTheme.colors.bundleGrey,
            color2: bundleTheme.colors.bundleGrey,
            color3: bundleTheme.colors.bundleBlack,
            color4: bundleTheme.colors.bundleGrey3,
            iconName1: 'img-upload-bundle',
            error: bundleTheme.colors.error,
            font: "",
            errorText: rebudTheme.colors.gray4,
        },
        themeLocationSearchInput: {
            font1: 't-font-DINNextLTPro-Regular',
            font2: '',
            font3: '',
            zipColor1: bundleTheme.colors.bundleWhite,
            zipColor2: bundleTheme.colors.bundleGrey2,
            padding: '1.375rem 16px',
            height1: '64px',
            height2: '64px',
            borderRadius: '4px',
            border: `solid 1px ${bundleTheme.colors.gray2}`,
            borderBottom: ``,
            font4: '',
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.bundleBlack
        },
        iconName5: 'store-pickup-bundle',
        checkoutStyles: {
            color1:  bundleTheme.colors.bundleBlack,
            font1:  'DINNextLTPro-Regular, sans-serif',
            color2:  bundleTheme.colors.bundleWhite,
            color3:  bundleTheme.colors.bundleGrey,
            color4:  bundleTheme.colors.bundleBlack,
            color5:  bundleTheme.colors.bundleGrey,
            color6:  bundleTheme.colors.bundleWhite,
            color7:  bundleTheme.colors.bundleBlack,
            color8:  bundleTheme.colors.white,
            color9:  bundleTheme.colors.gray3,
            color10: bundleTheme.colors.bundleWhite,
            color11: bundleTheme.colors.bundleGrey,
            color12: bundleTheme.colors.bundleWhite,
            color13: bundleTheme.colors.bundleGrey,
            color14: bundleTheme.colors.bundleWhite,
            color15: bundleTheme.colors.bundleWhite,
            color16: bundleTheme.colors.bundleWhite,
            color17: bundleTheme.colors.bundleWhite,
            color18: bundleTheme.colors.bundleGrey,
            color19: bundleTheme.colors.bundleGrey,
            fontSize1: '16px',
            color20: bundleTheme.colors.bundleBlack,
            color21: bundleTheme.colors.bundleGrey3,
            color22: bundleTheme.colors.bundleGrey3,
            color23: bundleTheme.colors.bundleGrey2,
            color24: bundleTheme.colors.bundleGrey2,
            color25: bundleTheme.colors.bundleGrey4,
            color26: bundleTheme.colors.brandDark,
            color27: bundleTheme.colors.brandDark,
            color28: bundleTheme.colors.gray4,
            color29: bundleTheme.colors.gray4,
            color30: bundleTheme.colors.bundleGrey3,
            color31: bundleTheme.colors.bundleBlack,
            color32: bundleTheme.colors.gray4,
            margin: '20px auto',
            overflow: 'scroll',
            color33: bundleTheme.colors.bundleGrey3,
            color34: bundleTheme.colors.bundleGrey,
            error: bundleTheme.colors.error,
            maxWidth: 'initial',
            font2: '',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: bundleTheme.colors.bundleWhite
        },
        summaryConfig: {
            font1: '',
            font2: '',
            font3: '',
            font4: '',
        },
        priceItemColor: bundleTheme.colors.bundleWhite,
        visibleRewards: false
    },
    headerConfig:{
        icon1: "bundle-icon",
        icon2: (props) => <FaChevronDown {...props} {...props} />,
        className1: 't-header-link',
        icon3: "cart",
        className2: '',
        toggleMenu: false,
        visibleCartIcon: false,
        styling: {
            color5: bundleTheme.colors.brandDark,
            fontFamily: "DINNextLTPro-MediumCond",
            borderBottom1: `3px solid ${bundleTheme.colors.bundleWhite}`,
            bgColor1: "",
            fontSize3: "24px",
            padding1: "6px 10px",
            padding2: "0 54px 0 8px",
            margin2: "0 auto 0 52px",
            width1: "90px",
            height1: "50px",
            width2: "180px",
            height2: "130px",
            margin: "0 auto 2px 4px",
            fontSize1: "32px",
            margin1: "36px",
            positionRight: "-26px",
            positionTop: "10px",
            padding3: "6px 4px 4px",
            minWidth: "26px",
            height3: "26px",
            color: bundleTheme.colors.white,
            bgColor2: bundleTheme.colors.bundleBlue,
            borderRadius: "14px",
            fontSize2: "20px",
            padding4: "10px",
            marginLeft: "36px",
            padding: "1.25rem 12px",
            marginLeft1: "50px",
            bgColor3: bundleTheme.colors.background,
            padding5: "",
            alignSelf: "",
        },
        inputSearchAhead: {
            color6: bundleTheme.colors.brandDark,
            icon: "bundle_search",
            button1: (props) => <ButtonOutline {...props} > SEARCH ALL PRODUCTS </ButtonOutline>,
            padding: "",
            svgPadding: "12px",
            svgWidth: "26px",
            svgHeight: "26px",
            bgColor: bundleTheme.colors.bundleBlack,
            borderBottom: "",
            inputPositionTop: "40%",
            inputPositionRight: "10px",
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.bundleWhite,
            color3: bundleTheme.colors.bundleBlack,
            bgColor2: rebudTheme.colors.bundleWhite,
            color4: bundleTheme.colors.bundleWhite,
            color5: bundleTheme.colors.bundleBlack,
            bgColor3: bundleTheme.colors.bundleWhite,
        },
        profileDropDownConfig:{
            icon: '',
            rewards: true,
            className1: "t-body-small ",
            text: "PROFILE",
            width: "",
            fontFamily: "DINNextLTPro-Regular",
            color: bundleTheme.colors.bundleBlack,
            bgColor: bundleTheme.colors.bundleGrey
        },
        headerMainSecondaryConfig:{
            borderBottom: "",
            padding: "",
            bgColor4: "",
            headerDeliveryInput: ()=> <HeaderDeliveryInputBundle/>
        },
        mainMenu: (props) => <MainMenu {...props} />,
        mobileMenu: (props) => <MobileMenuBundle {...props} />,
    },
    modalAddressConfig: {
        icon3: "delivery-circle-active",
        icon1: "bundle-icon",
        helloText: false,
        verifyText: <div className="t-font-DINNextLTPro-Regular verification__form__label-text">PLEASE VERIFY YOUR DELIVERY ADDRESS</div>,
        icon2: "close-X",
        headingText1: false,
        headingText2: <p className="verification__form__age-copy t-font-DINNextLTPro-Regular">ARE YOU 21 OR OVER? OR 18 WITH A MEDICAL CARD?</p>,
        color1: "var(--color-bundleColor)",
        button: (props) => <ButtonOutLineBundle {...props} className="verification__age__button" modalButton='true'/>,
        placeholder: 'ENTER YOUR ADDRESS',
        className1: 't-font-DINNextLTPro-Regular',
        className2: '',
        className3: "buttons-container",
        styling: {
            color10: bundleTheme.colors.brand,
            minHeight1: "250px",
            padding1: "0.5rem 16px 1.8125rem",
            minHeight2: "",
            backgroundRepeat: "",
            padding2: "0.5rem 16px 2.625rem",
            marginBottom1: "1.5rem",
            width1: "80px",
            height1: "60px",
            marginBottom2: "2rem",
            marginTop3: "1.5rem",
            width2: "150px",
            height2: "100px",
            paddingBottom1: '',
            color: bundleTheme.colors.bundleGrey,
            padding3: "16px 31px",
            bgColor1: bundleTheme.colors.bundleBlack,
            borderColor1: "",
            color1: bundleTheme.colors.bundleWhite,
            fontSize1: "",
            width: "",
            bgColor2: bundleTheme.colors.bundleWhite,
            borderColor2: bundleTheme.colors.bundleWhite,
            bgColor3: bundleTheme.colors.bundleBlack,
            borderColor3: "",
            color2: bundleTheme.colors.bundleWhite,
            marginTop: ".5rem",
            marginLeft: "",
            width3: "",
            fontSize2: "",
            padding: "14px 31px",
            maxWidth: "512px",
            marginBottom3: "2.5rem",
            marginTop1: "",
            maxWidth1: "",
            marginBottom4: "3.5rem",
            color3: bundleTheme.colors.brandDark,
            color4: "inherit",
            fontWeight: "",
            marginBottom5: "1rem",
            marginBottom6: "2rem",
            borderBottom2: `3px solid ${bundleTheme.colors.bundleWhite}`,
            lineHeight: "",
            fontFamily: "",
            fontSize3: "",
            linHeight2: "",
            color5: "#183f31",
            right: "3px",
            top: "15px",
            fill: bundleTheme.colors.bundleWhite,
            width4: "inherit",
            height4: "inherit",
            top2: "",
            margin: "10% 8px",
            maxWidth2: "724px",
            bgColor: bundleTheme.colors.bundleBlack,
            border: `3px solid ${bundleTheme.colors.bundleWhite}`,
            borderRadius: `25px`,
            maxWidth3: "710px",
            width5: "141px",
            minHeight3: "40px",
            width6: "180px",
            minHeight4: "58px",
            border2: `3px solid ${bundleTheme.colors.bundleWhite}`,
            marginLeft2: "20px",
            borderRadius2: "29px",
            color6: bundleTheme.colors.bundleBlack,
            bgColor4: bundleTheme.colors.background,
            color7: bundleTheme.colors.brandDark,
            border3: "none",
            borderColor: bundleTheme.colors.bundleWhite,
            paddingRight: "",
            padding5: "0 28px 3px 2px",
            width7: "90%",
            height: "24px",
            fontSize: '11px',
            padding4: "20px 22px 0 2px",
            width8: "513px",
            height5: "50px",
            borderRadius3: "0",
            fontSize5: "15px",
            margin3: "auto",
            paddingLeft: "17px",
            marginBottom: "",
            marginBottom7: "1rem",
            fontSize4: "17px",
            fontSize6: "25px",
            fontSize7: "17px",
            padding6: "0 43px",
            fontSize8: "25px",
            padding7: "0 12px",
            width9: "",
            height6: "",
            alignSelf: "",
            top3: "4px",
            right3: "40px",
            fill2: bundleTheme.colors.bundleGrey3,
            width10: "16px",
            height7: "16px",
            width11: "26px",
            height8: "26px",
            marginBottom8: "1.25rem",
            fontSize9: "25px",
            bgImage: "",
            fontFamily5: "",
            marginLeft3: "",
            fontFamily1: "",
            fontWeight1: "",
            radius: "0",
            color12: "transparent",
            color13: "#757575"
        }
    },
    cartItemsStyles:{
        color1: "#fff",
        color2: "#fff",
    },
    cartConfig: {
        mainConfig: {
            button1: (props) => <Button {...props} />,
            className1: "t-shelf-heading t-font-DINNextLTPro-Regular",
            className2: "t-eyebrow t-font-DINNextLTPro-Regular",
            className3: "t-card-copy",
            className4: "",
            className5: "t-body-small",
            className6: "",
            className7: "checkoutBtn",
            dark: true,
            className8: "t-footnote",
            className9: "",
            className10: "t-font-DINNextLTPro-Regular",
            promoComponent: false,
        },
        mainStyled:{
            font1: "var(--font-DINNextLTPro-Regular)",
            bgColor: bundleTheme.colors.bundleBlack,
            color1: bundleTheme.colors.bundleGrey,
            color2: bundleTheme.colors.bundleGrey,
            bgColor2: bundleTheme.colors.gray4,
            bgColor3: bundleTheme.colors.bundleWhite,
            color3: bundleTheme.colors.gray4,
            color4: bundleTheme.colors.gray4,
            color5: bundleTheme.colors.bundleWhite,
            borderBottom: `1px solid ${bundleTheme.colors.bundleGrey}`,
            font2: "var(--font-DINNextLTPro-Regular)",
            color6: bundleTheme.colors.bundleGrey,
            color7: bundleTheme.colors.gray2,
            color8: bundleTheme.colors.bundleGrey,
            bgColor4: bundleTheme.colors.bundleWhite,
            color9: bundleTheme.colors.bundleWhite,
            bgColor5: bundleTheme.colors.gray4,
            color10: bundleTheme.colors.bundleWhite,
            color11: bundleTheme.colors.bundleGrey2,
        },
        emptyCart: {
            className1: "",
            className2: "",
            color1: bundleTheme.colors.gray2,
        },
        addOnsProps: {
            className1: "t-eyebrow",
            className2: "",
            color1: bundleTheme.colors.bundleGrey,
            color2: bundleTheme.colors.gray2,
        },
        addOnsShelfProps: {
            className1: "t-card-copy",
            bgColor: bundleTheme.colors.background
        },
        cartItemProps: {
            className1: "",
            className2: "",
            className3: "",
            className4: "t-title-small",
            className5: "t-body-small",
            className6: "t-footnote",
            className7: "",
        },
        productCounterProps: {
            color1: bundleTheme.colors.bundleGrey3,
            color2: bundleTheme.colors.bundleWhite,
        },
        cartItemPromotionProps: {
            className1: "t-body t-font-DINNextLTPro-Regular",
            button: (props) => <ButtonSecondary {...props} />,
            className2: "t-footnote",
            fontFamily: "var(--font-DINNextLTPro-Regular)",
            bgColor: "",
            bgColor2: bundleTheme.colors.bundleGrey3,
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.brandDark,
            fill: bundleTheme.colors.bundleBlack,
            bgColor3: bundleTheme.colors.bundleBlack,

        }
    },
    productDetailsConfig:{
        viewPersonality: false,
        button: (props) => <ButtonPrimary {...props} noFocus />,
        className1: "t-body",
        className2: "t-section-title",
        className3: "",
        className4: "t-body-medium",
        className5: "t-body-medium t-font-lato-bold",
        className6: "t-eyebrow t-font-lato-bold",
        className7: "t-copy-heading",
        className8: "t-body",
        className9: "t-body-small t-bold",
        className10: "t-body-medium",
        className11: "t-section-heading",
        className12: "",
        cardText: "ADD TO BAG",
        productDetailsConfigStyled: {
            font: "",
            marginTop: "",
            marginBottom: "",
            margin: "25px 0 -30px 0",
            color1: rebudTheme.colors.accentSativa,
            color2: rebudTheme.colors.accentIndica,
            color3: rebudTheme.colors.accentHybrid,
            color4: rebudTheme.colors.accentCBD,
            color5: rebudTheme.colors.gray8,
        },
        isFirst: "Cannabinoids",
        productDescriptionDetailsConfigStyled: {
            font: "var(--font-artifex)",
            fontWeight: "",
            lineHeight1: "",
            fontSize1: "",
            lineHeight2: "",
            fontSize2: "",
            lineHeight3: "normal",
            letterSpacing: "",
            transition: "",
            font2: "",
            fontSize3: "",
            fontSize4: "",
        },
        productCardConfig: {
            className1: "t-body t-bold",
            className2: "t-body",
            className3: "t-body t-font-lato-bold",
            className4: "t-eyebrow t-font-lato-bold",
            className5: "t-body-small",
            className6: "t-title",
            className7: "t-list-footnote",
            className8: "",
            className9: "",
            className10: "t-body-small",
            productCardConfigStyled: {
                color1: rebudTheme.colors.gray8,
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginRight: "",
                color2: rebudTheme.colors.gray3,
                borderRadius: "",
                font: "",
                bgColor1: rebudTheme.colors.highLightLight,
                borderColor1: "",
                color: "",
                bgColor2: rebudTheme.colors.brandHighLight,
                color3: "",
                lineHeight: "",
                color4: rebudTheme.colors.accentSativa,
                color5: rebudTheme.colors.accentIndica,
                color6: rebudTheme.colors.accentHybrid,
                color7: rebudTheme.colors.accentCBD,
                color8: "",
            },
            cardText: "ADD TO BAG",
            className11: "",
        },

    },
    homeConfig: {
        personalityActive: false,
        dynamicShelfActive: false,
        heroBannerActive:  true,
        brandSpotlightActive:  true,
        productSalesActive:  false,
        staffPicksActive:  false,
        discoverAllActive:  false,
        styles: {
            bgColor: bundleTheme.colors.background,
            fontFamily: 'var(--font-DINNextLTPro-MediumCond)',
            gridTemplate: "repeat(auto-fill, minmax(300px, 1fr))",
        },
        heroBanner:{
            bannerText: true,
            styles:{
                display: "grid",
                gridTemplateColumns: "1fr min(1808px, 100%) 1fr",
                marginTop: "1.5rem",
                minHeight: "394px",
                marginTop1: "3rem",
                marginBottom: "3rem",
                marginLeft: "16px",
                width: "calc(100% - 16px)",
                marginLeft1: "32px",
                width1: "calc(100% - 32px)",
                hover: "scale(1.02)",
                maxWidth: "1680px",
            }
        },
        sectionShelfGridConfig: {
            className1: "t-shelf-heading",
            className2: "t-shelf-link",
            styles:{
                fontFamily: "var(--font-DINNextLTPro-Regular)",
                marginTop: "2.5em",
                color: bundleTheme.colors.brandDark,
                display: "inline-flex",
                paddingBottom: "",
                backgroundImage: ``,
                backgroundPosition: "", /*OR bottom left*/
                backgroundSize: "",
                backgroundRepeat: "",
                transition: "",
                position: "",
                color1: bundleTheme.colors.bundleWhite,
                margin1: "",
                backgroundPosition1: "",
                backgroundSize1: "",
                textDecoration: "underline",
                marginTop1: "",
                borderColor: rebudTheme.colors.brandDark,
                fontSize: "35px",
                fontFamily1: "var(--font-DINNextLTPro-MediumCond)",
                fontSize1: "26px",
                fontSize2: "32px",
            }
        },
        brandSpotlightConfig:{
            subHeading: false,
            presentation: false,
            className1: "t-body-medium",
            className2: "t-section-heading",
            className3: "t-body",
            className4: "",
            styles: {
                bgColor: bundleTheme.colors.bundleWhite,
                marginLeft: "",
                width: "100%",
                maxWidth: "",
                marginLeft1: "",
                padding: "",
                maxWidth1:"unset",
                width1: "100%",
                gridTemplateColumns: "1fr 1fr",
                gridColumnGap: "",
                marginLeft2: "",
                maxWidth2: "",
                width2: "",
                padding1: "92px",
                gridColumn: 2,
                color: bundleTheme.colors.bundleBlack,
                fontFamily: "var(--font-DINNextLTPro-MediumCond)",
                textTransform: "uppercase",
                paddingBottom: "",
                marginBottom: "76px",
                color1: bundleTheme.colors.bundleBlack,
                fontSize: "25px",
                fontFamily1: "var(--font-DINNextLTPro-Regular)",
                fontSize1: "20px",
                padding2: "36px",
                maxWidth3: "",
                width3: "336px",
                border: `3px solid ${bundleTheme.colors.bundleBlack}`,
                borderRadius: "52px",
                fontSize2: "",
                color3: bundleTheme.colors.bundleWhite,
                bgColor1: bundleTheme.colors.bundleBlack,
                width4: "222px",
                padding3: "24px",
                fontSize3: "20px",
                bgColor2: "",
                gridColumn2: 1,
                display: "",
                justifyContent: "",
                alignItems: "",
                padding4: "",
                paddingTop: "4.75rem",
                color2: bundleTheme.colors.brandDark,
                lineHeight: "",
                color4: bundleTheme.colors.bundleBlack,
                borderColor: "",
                color5: ""
            }
        },
        brandCardConfig:{
            className1: "t-brand-eyebrow",
            visibleBrandDesc: false,
            styles: {
                paddingLeft : "8px",
                color: bundleTheme.colors.brandDark,
                color1: bundleTheme.colors.gray3,
                border: `3px solid ${bundleTheme.colors.white}`,
                borderRadius: "24px",
                width: "auto",
                margin: "20px",
                marginLeft1: "16px",
                fontSize: "25px",
                fontFamily: "var(--font-DINNextLTPro-Regular)",

            }
        }
    },
    privacyConfig:{
        className1: 't-section-heading',
        fontFamily: '',
        className2: 't-section-subheading',

    }
}
export default configBundle;