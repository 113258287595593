/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {locationActions, globals, categoriesFunc} from '../../actions';
import StyledModal from "./Modal.styled";
import ModalSimple from "../ModalSimple/ModalSimple";
import StyledHeaderDeliveryInputEnjoyable from "./HeaderDeliveryInputEnjoyable.styled";
import SVGIcon from "../utility/SVGIcon";
import LocationSearchInput from "../../components/LocationSearchInput/LocationSearchInput";
import {storeDomain, storeType} from "../../config/constants";
import { locationPhonePT }  from "../../config/prop-types";
import ModalChangeAddress from "../ModalChangeAddress/ModalChangeAddress";

let zipCode = null;

const HeaderDeliveryInputEnjoyable = React.memo(({dispatch, address, isBundleTheme}) => {

  const [deliveryLocation, setDeliveryLocation] = useState({
    address: "",
    lat: "",
    lng: "",
    zip: ""
  });
  // eslint-disable-next-line no-unused-vars
  const [deliveryStatus, setDeliveryStatus] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [openLocation, setOpenLocation] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [initial, setInitial] = useState(true);
  const [isAddress, setIsAddress] = useState(true);

  // Handle deliver address modal
  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = () => setShowModal(!showModal);
  const handleShowModal = (newValue) => {
    toggleShowModal(newValue);
  }

  const myRef = useRef();

  useEffect(() => {
    dispatch(locationActions.selectedLocation());
    dispatch(locationActions.locationInfo());
    dispatch(globals.cartCount());
    dispatch(categoriesFunc.featuredBrandsAll());
  }, []);

  useEffect(() => {
    if(address){
      const data = {
        ...deliveryLocation,
        address: address.address,
        lat: address.lat,
        lng: address.lng,
        zip: address.zip,
      };
      setDeliveryLocation(data);
      setInitial(false);
      // handleOnSelect(address.address)
    }
  }, [ address ]);

  useEffect(()=>{
    if (localStorage.getItem("token")) {
      dispatch(globals.userInfo());
    }
  }, [])

  const handleOnChange = address => {
    const data = { ...deliveryLocation };
    data.address = address;
    setDeliveryLocation(data);
  };

  const getResultData = (Data, name) => {
    const Result = Data.address_components.filter(
      item => item.types[0] === name
    );
    if (Result.length > 0) {
      return Result[0].long_name;
    }
    return null;
  };

  const getZipCode = async result => {
    zipCode = getResultData(result, "postal_code");
    return result;
  };

  const handleOnSelect = address => {
    const data = { ...deliveryLocation };
    // setSelectLoading(true);
    geocodeByAddress(address)
    .then(results => getZipCode(results[0]))
    .then(results => getLatLng(results))
    .then(latLng => {
      data.address = address;
      data.lat = latLng.lat;
      data.lng = latLng.lng;
      data.zip = zipCode;
      setDeliveryLocation(data);
      // checkLocation(data);
      locationActions.locationSelect(data)
      .then(
        data1 => {
          if(data1.status){
            if(data1.status !== 'notDelivery'){
              localStorage.setItem('deliveryStatus', JSON.stringify(data1.status));
              localStorage.setItem('storeStatus', JSON.stringify(data1.status));
              handleSelectLocation(data);
            }
            else{
              toggleShowModal(true);
            }
            setDeliveryStatus(data1.status);
          }
        }
      );
    });
  };

  const clearSearch = () =>{
    const data = { ...deliveryLocation };
    data.address = '';
    setDeliveryLocation(data);
  }

  const handleSelectLocation = data => {
    locationActions.locationSelectInHeader(data)
      .then(
        (data) => {
          setOpenLocation(false);
          if(data.GUID){
            localStorage.setItem(`guId_${storeDomain}`, JSON.stringify(data.GUID));
          }
          window.location.reload();
        }
      );
  };

  // eslint-disable-next-line no-unused-vars
  const handleOpenLocation = () =>{
    setOpenLocation(true)
  };

  const handleClickOutside = e => {
    if(!myRef.current.contains(e.target) && !e.target.closest('.simple-modal') && !e.target.classList.contains('background')){
      setOpenLocation(false)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleBlur = (e) => {
    const { name } = e.target;
    if(!isAddress) {
      const data = {
        ...deliveryLocation,
        [name]: address.address
      };
      setDeliveryLocation(data);
    }
  };

  const handleWrongAddress = () => {
    if(!isAddress) {
      const data = {
        ...deliveryLocation,
        address: address.address
      };
      setDeliveryLocation(data);
    }
  };
  const [showAddressPopup, setShowAddressPopup] = useState(false)
  return (
    <>
    <StyledHeaderDeliveryInputEnjoyable isBundleTheme={isBundleTheme} >
      <h2 className="header-delivery__heading">Delivery Location</h2>

      <label className="header-delivery__label" ref={myRef} onClick={()=>setShowAddressPopup(true)}>
          <SVGIcon
            name="everscore-location-icon"
            className="header-delivery__icon"
          />
          <span className="t-hidden">delivery address</span>
          <LocationSearchInput
            address={deliveryLocation.address || ''}
            handleChange={handleOnChange}
            handleSelect={handleOnSelect}
            className="header-delivery__location-input"
            onBlur={handleBlur}
            setIsAddress={setIsAddress}
            handleWrongAddress={handleWrongAddress}
            openPopup={true}
          />

          {deliveryLocation.address &&
            <button className="header-delivery__clear" onClick={clearSearch}>
              {isBundleTheme ?
                <SVGIcon name="close-X" /> :
                <SVGIcon name="closeCircle" width="16" />
              }
              <span className="t-hidden">clear search</span>
            </button>
          }

        </label>
    </StyledHeaderDeliveryInputEnjoyable>
    <StyledModal>
      {
        isBundleTheme ?
            <ModalSimple shown={showModal} onChange={handleShowModal} className="address-modal">
              <h2 className="address-modal__heading t-section-heading">Sorry, friend....</h2>
              <p className="t-body-medium">We’re not delivering to that location yet.</p>
            </ModalSimple> :
            <ModalSimple shown={showModal} onChange={handleShowModal} className="address-modal">
              <h2 className="address-modal__heading t-section-heading">Sorry, friend....</h2>
              <p className="t-body-medium">We’re not delivering to that location yet.</p>
            </ModalSimple>

      }
    </StyledModal>
      <ModalChangeAddress isShown={showAddressPopup} onChange={setShowAddressPopup} />
    </>
  );
});

const mapStateToProps = ({ userAddress, locationInfo, cartCount, featuredBrandAll, categoriesAll, personalityAll }) => ({
  address: userAddress.address,
  locationPhone: locationInfo.locationPhone,
  cartCount: cartCount.count,
  featuredBrand: featuredBrandAll.featuredBrandsListAll,
  categories: categoriesAll.catListAll,
  personality: personalityAll.personalityListAll,
});

HeaderDeliveryInputEnjoyable.propTypes = {
  // brandLogo: PropTypes.string,
  brandName: PropTypes.string,
  locationPhone: locationPhonePT,
  cartCount: PropTypes.number,
  featuredBrand: PropTypes.array,
  categories: PropTypes.array,
};

export default connect(mapStateToProps)(HeaderDeliveryInputEnjoyable);
