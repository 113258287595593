import styled from "styled-components";

const StyledModalVerification = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform : ${props => props.isShown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity : ${props => props.isShown ? '1' : '0'};
  z-index: 10;
  filter: blur(0) !important;

  .verification {

    margin: ${({themeProps}) => themeProps.margin};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${({themeProps}) => themeProps.maxWidth2};
    min-height: ${({themeProps}) => themeProps.minHeight1};
    background-color: ${({themeProps}) => themeProps.bgColor};
    background-image: ${({themeProps}) => themeProps.bgImage};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 12;
    border: ${({themeProps}) => themeProps.border};
    border-radius: ${({themeProps}) => themeProps.borderRadius};
    
    @media (max-width: 799px) {
      padding: ${({themeProps}) => themeProps.padding1};
      max-width: 360px;
      min-height: ${({themeProps}) => themeProps.minHeight2};
      background-repeat: ${({themeProps}) => themeProps.backgroundRepeat};
    }
    @media (min-width: 800px) {
      padding: ${({themeProps}) => themeProps.padding2};
      max-width: ${({themeProps}) => themeProps.maxWidth3};
    }
  }
  
  .verification__logo {
    align-self: center;
    
    @media (max-width: 799px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom1};
      width: ${({themeProps}) => themeProps.width1};
      max-height: ${({themeProps}) => themeProps.height1};
    }
    @media (min-width: 800px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom2};
      margin-top: ${({themeProps}) => themeProps.marginTop3};
      width: ${({themeProps}) => themeProps.width2};
      max-height: ${({themeProps}) => themeProps.height2};
    }
  }

  .verification__heading {
    color: ${({theme}) => theme.colors.brandDark};

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 1rem;
    }
  }
  
  .verification__age {
    width: 100%;
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    padding-bottom: ${({themeProps}) => themeProps.paddingBottom1};
    
    &.is-disabled {
      color: ${({themeProps}) => themeProps.color};
    }
  }
  
  // Button / Link overwrite, change with future button and link design cleanup
  .verification__button,
  .verification__age__button {
    @media (max-width: 799px) {
      width: ${({themeProps}) => themeProps.width5};
      min-height: ${({themeProps}) => themeProps.minHeight3};
    }
    @media (min-width: 800px) {
      width: ${({themeProps}) => themeProps.width6};
      min-height: ${({themeProps}) => themeProps.minHeight4};
    }
  }
  .verification__yes__button {
    margin-right: 18.28px;
    padding: 8px 31px;
    width: 141.77px;
    color: ${({ theme }) => theme.colors.gray4};
    @media (max-width: 799px) {
      margin-right: 15.88px;
      font-size: var(--type-scale-14);
      width: 111.95px;
      padding: 8px 31px;
    }
  }
  // Button / Link overwrite, change with future button and link design cleanup
  .verification__button {
    padding: ${({themeProps}) => themeProps.padding3};
    line-height: normal;
    display: inline-block;
    border: ${({themeProps}) => themeProps.border2};
    background-color: ${({themeProps}) => themeProps.bgColor1};
    border-color: ${({themeProps}) => themeProps.borderColor1};
    color:${({themeProps}) => themeProps.color1};
    font-size: ${({themeProps}) => themeProps.fontSize1};
    font-weight: var(--weight-bold);
    font-family: ${({themeProps}) => themeProps.fontFamily5};
    text-align: center;
    flex-shrink: 0;
    transition: var(--button-transition);
    text-decoration: none;
    width: ${({themeProps}) => themeProps.width};
    opacity: 1;
    border-radius: ${({themeProps}) => themeProps.borderRadius2};
    margin-left: ${({themeProps}) => themeProps.marginLeft2};

    &:hover,
    &:focus {
      background-color: ${({themeProps}) => themeProps.bgColor2};
      border-color: ${({themeProps}) => themeProps.borderColor2};
      color: ${({themeProps}) => themeProps.color6};
    }

    &.is-disabled {
      background-color: ${({themeProps}) => themeProps.bgColor3};
      border-color: ${({themeProps}) => themeProps.borderColor3};
      color: ${({themeProps}) => themeProps.color2};
      cursor: default;
    }

    @media (max-width: 799px) {
      margin-top: ${({themeProps}) => themeProps.marginTop};
      margin-left: ${({themeProps}) => themeProps.marginLeft3};
      font-size: ${({themeProps}) => themeProps.fontSize2};
      width: ${({themeProps}) => themeProps.width3};
      padding: ${({themeProps}) => themeProps.padding};
    }
    @media (min-width: 800px) {
      margin-left: ${({themeProps}) => themeProps.marginLeft};
    }
  }

  .verification__form {
    display: flex;
    flex-direction: column;
    max-width: ${({themeProps}) => themeProps.maxWidth};
    width: 100%;

    @media (max-width: 799px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom3};
      margin-top: ${({themeProps}) => themeProps.marginTop1};
      max-width: ${({themeProps}) => themeProps.maxWidth1};
    }
    @media (min-width: 800px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom4};
    }
  }

  .verification__form__label {
    width: 100%;  
    position: relative;
    color:  ${({themeProps}) => themeProps.color3};
    margin: ${({themeProps}) => themeProps.margin3};
    @media (max-width: 799px) {
      padding-left: ${({themeProps}) => themeProps.paddingLeft};
    }
  }
  
  .verification__form__age-heading {
    margin-bottom: .75rem;
    color: inherit;

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: .75rem;
    }
  }
  
  .verification__form__label-text {
    margin-bottom: ${({themeProps}) => themeProps.marginBottom};
    font-family: ${({themeProps}) => themeProps.fontFamily1};
    font-weight: ${({themeProps}) => themeProps.fontWeight1};
    @media (max-width: 799px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom7};
      font-size:${({themeProps}) => themeProps.fontSize4};
    }
    @media (min-width: 800px) {
      font-size: ${({themeProps}) => themeProps.fontSize6};
    }
  }
  .verification__form__label-text-everscore {
    margin-bottom: 25.3px;
    font-size: 23px;
    font-weight: var(--weight-bold);
    line-height: 23px;
    text-align: center;
    @media (max-width: 799px) {
      margin-bottom: 18.86px;
      font-size: 20px;
      line-height: 23px;
    }
  }
  .verification__form__label-text-second {
    margin-bottom: 19.63px;
    font-size: 18px;
    line-height: 21.6px;
    text-align: center;
    @media (max-width: 799px) {
      margin-bottom: 10.96px;
      line-height: 15.6px;
      font-size: 13px;
      width: calc(100% - 10px);
      letter-spacing: inherit;
    }
  }

  .first {
    margin: 0;
  }
  .verification__form__age-copy {
    color: ${({themeProps}) => themeProps.color4};
    font-weight: ${({themeProps}) => themeProps.fontWeight};
    font-family: ${({themeProps}) => themeProps.fontFamily1};

    @media (max-width: 799px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom5};
      font-size:${({themeProps}) => themeProps.fontSize7};
      padding: ${({themeProps}) => themeProps.padding6};
    }
    @media (min-width: 800px) {
      margin-bottom: ${({themeProps}) => themeProps.marginBottom6};
      font-size:${({themeProps}) => themeProps.fontSize8};
    }
  }

  .verification__footnote {
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.brandDark};

    .t-link {
      color: inherit;
    }
    .t-link:hover,
    .t-link:focus {
      color: ${({theme}) => theme.colors.brand};
    }
  }
  .not_deliv{
    margin: 3px 0;
    font-size: 12px;
  }

  /* Temporary until v2 design */
  .verification__input {
    background-color: ${({themeProps}) => themeProps.bgColor4};
    color: ${({themeProps}) => themeProps.color7};
    border-radius: 0;
    border: ${({themeProps}) => themeProps.border3};
    border-bottom: ${({themeProps}) => themeProps.borderBottom2};
    transition: var(--button-transition);
    padding-right: ${({themeProps}) => themeProps.paddingRight};;
    line-height: ${({themeProps}) => themeProps.lineHeight};
    font-family: ${({themeProps}) => themeProps.fontFamily};
    outline: none;
    &:hover,
    &:focus {
      border-color: ${({themeProps}) => themeProps.fontFamily};
    }
    @media (max-width: 799px) {
      font-size: ${({themeProps}) => themeProps.fontSize3};
      line-height: ${({themeProps}) => themeProps.linHeight2};
    }
    @media (max-width: 859px) {
      padding: ${({themeProps}) => themeProps.padding5};
      width: ${({themeProps}) => themeProps.width7};
      height:${({themeProps}) => themeProps.height};
      font-size: ${({themeProps}) => themeProps.fontSize};
    }

    @media (min-width: 860px) {
      padding: ${({themeProps}) => themeProps.padding4};
      width: ${({themeProps}) => themeProps.width8};
      height: ${({themeProps}) => themeProps.height5};
      border-radius: ${({themeProps}) => themeProps.borderRadius3};
      font-size: ${({themeProps}) => themeProps.fontSize5};
    }
  }

  .verification__form__address-input {
    position: relative;
    @media (max-width: 799px) {
      padding: ${({themeProps}) => themeProps.padding7};
    }
  }
  .verification__form__ip-address-input {
    margin-top: 15px;
    .placeHold{
      display: none;
    }
    .verification__form__label-text{
      color: ${({themeProps}) => themeProps.color5};
    }
    input{
      border-radius: 0;
    }
  }
  .verification-delivery_address__clear {
    border: none;
    outline: none;
    position: absolute;
    right: ${({themeProps}) => themeProps.right};
    top: ${({themeProps}) => themeProps.top};
    width: ${({themeProps}) => themeProps.width9};
    height: ${({themeProps}) => themeProps.height6};
    align-self: ${({themeProps}) => themeProps.alignSelf};
    svg {
      fill: ${({themeProps}) => themeProps.fill};
      width: ${({themeProps}) => themeProps.width4};
      height: ${({themeProps}) => themeProps.height4};

    }
    @media (max-width: 799px) {
      top: ${({themeProps}) => themeProps.top3};
      right: ${({themeProps}) => themeProps.right3};

      svg {
        fill: ${({themeProps}) => themeProps.fill2};
        width: ${({themeProps}) => themeProps.width10};
        height: ${({themeProps}) => themeProps.height7};
      }
    }
    @media (min-width: 800px) {
      width: ${({themeProps}) => themeProps.width11};
      height: ${({themeProps}) => themeProps.height8};
      margin-bottom: ${({themeProps}) => themeProps.marginBottom8};
      font-size: ${({themeProps}) => themeProps.fontSize9};
    }
    
    
    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
    @media (max-width: 799px) {
      top: ${({themeProps}) => themeProps.top2};
    }
  }
  .buttons-container {
    @media (max-width: 799px) {
      display: flex;
      justify-content: center;
    }
    @media (min-width: 800px) {
      display: block;
    }
  }
`;

export default StyledModalVerification;
