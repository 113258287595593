import rebudTheme from '../../styles/themes/DefaultTheme';
import {FaChevronDown} from "react-icons/fa";
import React from "react";
import ButtonSecondary from "../../components/ui/ButtonSecondary";
import HeaderDeliveryInput from "../../components/HeaderDeliveryInput/HeaderDeliveryInput";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import MainMenu from "../../components/MainMenu/MainMenu";
import ButtonOutline from "../../components/ui/ButtonOutline";
import flowerTheme from "../../styles/themes/FlowerTheme";
import ButtonPrimary from "../../components/ui/ButtonPrimary";
import bundleTheme from "../../styles/themes/BundleTheme";
import enjoyableTheme from "../../styles/themes/EnjoyableTheme";

const configRebud = {
    contactUsConfig: {
        background:'',
        colorFonts:'',
        classMainFont: '',
        classNameTitleFont: '',
        classNameDescription: '',
        color: 'brandDark',
        pstText: 'PST',
        mainPageHeadClass1: 't-section-title',
        mainPageHeadClass2: 't-link',
        mainPageHeadClass3: '',
        mainPageHeadClass4: '',
        contactFormFont: '',
        contactFormClass: 't-section-heading',
        buttonFont: '',
        buttonColor: "",
    },
    checkoutConfig: {
        headingClass: 't-section-heading',
        classNameFont: '',
        fontName1:  '',
        iconName1:  'checked',
        iconName2:  'check',
        steps: {
               font1: '',
               font2: 't-subtitle-large',
               font3: '',
               font4: '',
               font5: '',
               font6: '',
               font7: '',
               color1: rebudTheme.colors.gray2,
               color2: '',
               color3: rebudTheme.colors.brandDark,
               color4: rebudTheme.colors.gray3,
               classNameFont1: '',
        },
        simpleModal: {
            color1: rebudTheme.colors.white,
            color2: ''
        },
        mainFont1: 't-title-small',
        mainFont2: '',
        mainFont3: '',
        mainFont4: '',
        iconName3: 'delivery-circle-active',
        mainFont5: '',
        iconName4: 'delivery-normal-box',
        mainFont6: '',
        dark: true,
        mainFont7: '',
        mainFont8: '',
        mainFont9: 't-font-lato-regular',
        mainText1: 'Rebud',
        inputAuth1: {
            font: '',
            color1: '#f5f5f5',
            color2: rebudTheme.colors.gray5,
            color3: rebudTheme.colors.gray2,
            color4: rebudTheme.colors.white,
            color5: rebudTheme.colors.brandDark,
            color6: rebudTheme.colors.gray1,
            color7: rebudTheme.colors.gray3,
            color8: rebudTheme.colors.brandDark,
            font1: '',
            error: rebudTheme.colors.error,
            color9: rebudTheme.colors.gray3,
            color10: "#fff",
            color11: rebudTheme.colors.gray3,
        },
        dropUpload: {
            color1: '',
            color2: rebudTheme.colors.gray1,
            color3: '',
            color4: rebudTheme.colors.webGrey4,
            iconName1: 'upload-icon',
            error: rebudTheme.colors.webGrey4,
            font: "",
            errorText: rebudTheme.colors.newErrorForm  ,
        },
        themeLocationSearchInput: {
            font1: 't-form-input',
            font2: '',
            font3: '',
            zipColor1: rebudTheme.colors.white,
            zipColor2: rebudTheme.colors.gray3,
            padding: '1.375rem 16px',
            height1: '64px',
            height2: '64px',
            borderRadius: '4px',
            border: `solid 1px ${rebudTheme.colors.gray2}`,
            borderBottom: ``,
            font4: '',
            color1: '#ffffff',
            color2: ''
        },
        iconName5: 'store-pickup',
        checkoutStyles: {
            color1: '',
            font1: '',
            color2: '',
            color3: rebudTheme.colors.gray4,
            color4: rebudTheme.colors.brandDark,
            color5: rebudTheme.colors.white,
            color6: rebudTheme.colors.brand,
            color7: rebudTheme.colors.white,
            color8: '',
            color9: '',
            color10: '',
            color11: rebudTheme.colors.webGrey4,
            color12: rebudTheme.colors.gray5,
            color13: "#ccc",
            color14: rebudTheme.colors.gray1,
            color15: rebudTheme.colors.webGreen,
            color16: rebudTheme.colors.white,
            color17: rebudTheme.colors.black,
            color18: rebudTheme.colors.brandDark,
            color19: '',
            ontSize1: '',
            color20:  rebudTheme.colors.gray3,
            color21: rebudTheme.colors.brandDark,
            color22: '',
            color23: rebudTheme.colors.brandDark,
            color24: rebudTheme.colors.brand,
            color25: '',
            color26: rebudTheme.colors.gray3,
            color27: rebudTheme.colors.gray4,
            color28: '',
            color29: rebudTheme.colors.gray1,
            color30: rebudTheme.colors.gray2,
            color31: rebudTheme.colors.black,
            color32: rebudTheme.colors.white,
            margin: '0 auto',
            overflow: 'initial',
            color33: '#757575',
            color34: rebudTheme.colors.brandDark,
            error: rebudTheme.colors.error,
            maxWidth: 'initial',
            font2: '',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: ''
        },
        summaryConfig: {
            font1: '',
            font2: '',
            font3: '',
            font4: '',
        },
        priceItemColor: '#222',
        visibleRewards: true
    },
    headerConfig:{
        icon1: "rebud-watermark",
        icon2: (props) => <FaChevronDown {...props} />,
        className1: 't-header-link',
        icon3: "cart",
        className2: '',
        toggleMenu: true,
        visibleCartIcon: true,
        styling: {
            color5: rebudTheme.colors.brandDark,
            fontFamily: "",
            borderBottom1: `1px solid ${rebudTheme.colors.gray2}`,
            bgColor1: rebudTheme.colors.white,
            fontSize3: "",
            padding1: "10px 8px 10px 0",
            padding2: "0 32px",
            margin2: "",
            width1: "86px",
            height1: "40px",
            width2: "116px",
            height2: "50px",
            margin: "initial",
            fontSize1: "",
            margin1: "10px",
            positionRight: "-10px",
            positionTop: "0",
            padding3: "4px",
            minWidth: "20px",
            height3: "20px",
            color: rebudTheme.colors.background,
            bgColor2: rebudTheme.colors.brand,
            borderRadius: "10px",
            fontSize2: "12px",
            padding4: "8px",
            marginLeft: "20px",
            padding: "1.25rem 12px",
            marginLeft1: "50px",
            bgColor3: rebudTheme.colors.background,
            padding5: "",
            alignSelf: "",
        },
        inputSearchAhead: {
            color6: rebudTheme.colors.brandDark,
            icon: "search",
            button1: (props) => <ButtonSecondary {...props} > SEARCH ALL PRODUCTS </ButtonSecondary>,
            padding: "2rem",
            svgPadding: "8px",
            svgWidth: "22px",
            svgHeight: "22px",
            bgColor: "",
            borderBottom: `solid 1px ${rebudTheme.colors.gray4}`,
            inputPositionTop: "50%",
            inputPositionRight: "0",
            color1: rebudTheme.colors.gray3,
            color2: rebudTheme.colors.gray4,
            color3: "",
            bgColor2: rebudTheme.colors.gray1,
            color4: "",
            color5: "",
            bgColor3: "",
        },
        profileDropDownConfig:{
            icon: 'user',
            rewards: true,
            className1: "t-body-small ",
            text: "",
            width: "40px",
            fontFamily: "",
            color: rebudTheme.colors.brand,
            bgColor: ""
        },
        headerMainSecondaryConfig:{
            borderBottom: `1px solid ${rebudTheme.colors.gray2}`,
            padding: `6px 32px`,
            bgColor4: rebudTheme.colors.white,
            headerDeliveryInput: ()=> <HeaderDeliveryInput/>
        },
        mainMenu: (props) => <MainMenu {...props} />,
        mobileMenu: (props) => <MobileMenu {...props} />,

    },
    modalAddressConfig: {
        icon1: "rebud-watermark",
        icon3: "delivery-circle-active",
        helloText: true,
        verifyText: <div className="t-sub-heading verification__form__label-text">Where should we deliver your stuff?</div>,
        icon2: "closeCircle",
        headingText1: true,
        headingText2: <p className="t-sub-heading verification__form__age-copy">Are you 21+? or 18+ with a medical
            card?</p>,
        color1: "var(--color-everscoreBrandConst)",
        button: (props) => <ButtonOutline  className="verification__age__button" {...props}/>,
        placeholder: '',
        className1: 't-sub-heading',
        className2: '',
        className3: "",
        styling: {
            color10: rebudTheme.colors.brand,
            minHeight1: "250px",
            padding1: "2.5rem 16px 1.8125rem",
            minHeight2: "",
            backgroundRepeat: "",
            padding2: "3rem 16px 3.625rem",
            marginBottom1: "1.5rem",
            width1: "86px",
            height1: "40px",
            marginBottom2: "1.5rem",
            marginTop3: "0",
            width2: "120px",
            height2: "60px",
            paddingBottom1: '6rem',
            color: rebudTheme.colors.gray3,
            padding3: "14px 31px",
            bgColor1: rebudTheme.colors.white,
            borderColor1: "",
            color1: rebudTheme.colors.brandDark,
            fontSize1: "var(--type-scale-16)",
            width: "",
            bgColor2: rebudTheme.colors.brand,
            borderColor2: rebudTheme.colors.brand,
            bgColor3: rebudTheme.colors.white,
            borderColor3: rebudTheme.colors.gray3,
            color2: rebudTheme.colors.gray3,
            marginTop: ".5rem",
            marginLeft: "16px",
            width3: "",
            fontSize2: "",
            padding: "",
            maxWidth: "512px",
            marginBottom3: "1.5rem",
            marginTop1: "",
            maxWidth1: "",
            marginBottom4: "2rem",
            color3: rebudTheme.colors.brandDark,
            color4: "",
            fontWeight: "",
            marginBottom5: "1rem",
            marginBottom6: "1.25rem",
            borderBottom2: "",
            lineHeight: "",
            fontFamily: "",
            fontSize3: "",
            linHeight2: "",
            color5: "#183f31",
            right: "14px",
            top: "1.625rem",
            fill: rebudTheme.colors.gray3,
            width4: "inherit",
            height4: "inherit",
            top2: "",
            margin: "0 8px 3% 8px",
            maxWidth2: "724px",
            bgColor: rebudTheme.colors.white,
            border: "",
            borderRadius: "",
            maxWidth3: "",
            width5: "100%",
            minHeight3: "",
            width6: "144px",
            minHeight4: "",
            border2: `1px solid ${rebudTheme.colors.brandDark}`,
            marginLeft2: "",
            borderRadius2: "",
            color6: rebudTheme.colors.white,
            bgColor4: "transparent",
            color7: "",
            border3: "",
            borderColor: rebudTheme.colors.brand,
            paddingRight: "40px",
            padding5: "",
            width7: "",
            height: "",
            fontSize: '',
            padding4: "",
            width8: "",
            height5: "",
            borderRadius3: "",
            fontSize5: "",
            margin3: "",
            paddingLeft: "",
            marginBottom: ".75rem",
            marginBottom7: "",
            fontSize4: "",
            fontSize6: "",
            fontSize7: "",
            padding6: "",
            fontSize8: "",
            padding7: "",
            width9: "16px",
            height6: "16px",
            alignSelf: "center",
            top3: "",
            right3: "",
            fill2: "",
            width10: "",
            height7: "",
            width11: "",
            height8: "",
            marginBottom8: "",
            fontSize9: "",
            bgImage: "",
            fontFamily5: "",
            marginLeft3: "",
            fontFamily1: "",
            fontWeight1: "",
            radius: "4px",
            color12: rebudTheme.colors.brandDark,
            color13: ""

        }
    },
    cartItemsStyles:{
        color1: rebudTheme.colors.webGrey4,
        color2: rebudTheme.colors.gray4,
    },
    cartConfig: {
        mainConfig: {
            button1: (props) => <ButtonPrimary {...props} />,
            className1: "t-shelf-heading",
            className2: "t-eyebrow",
            className3: "t-card-copy",
            className4: "",
            className5: "t-body-small",
            className6: "",
            className7: "checkoutBtn",
            dark: false,
            className8: "t-footnote",
            className9: "",
            className10: "",
            promoComponent: true,
        },
        mainStyled:{
            font1: "",
            bgColor: "",
            color1: rebudTheme.colors.gray3,
            color2: "",
            bgColor2: "",
            bgColor3: "",
            color3: "",
            color4: rebudTheme.colors.gray1,
            color5: "",
            borderBottom: `1px solid ${rebudTheme.colors.gray2}`,
            font2: "",
            color6: "",
            color7: rebudTheme.colors.gray3,
            color8: rebudTheme.colors.gray4,
            bgColor4: rebudTheme.colors.gray1,
            color9: rebudTheme.colors.brand,
            bgColor5: "",
            color10: "",
            color11: "#888888",
        },
        emptyCart: {
            className1: "",
            className2: "",
            color1: rebudTheme.colors.gray3,
        },
        addOnsProps: {
            className1: "t-eyebrow",
            className2: "",
            color1: "",
            color2: rebudTheme.colors.gray3,
        },
        addOnsShelfProps: {
            className1: "t-card-copy",
            bgColor: rebudTheme.colors.white
        },
        cartItemProps: {
            className1: "",
            className2: "",
            className3: "",
            className4: "t-title-small",
            className5: "t-body-small",
            className6: "t-footnote",
            className7: "",
        },
        productCounterProps: {
            color1: rebudTheme.colors.gray2,
            color2: "#fff",
        },
        cartItemPromotionProps: {
            className1: "t-body",
            button: (props) => <ButtonSecondary {...props} />,
            className2: "t-footnote",
            fontFamily: "",
            bgColor: rebudTheme.colors.highLightLight,
            bgColor2: rebudTheme.colors.brandHighLight,
            color1: "",
            color2: "",

            fill: rebudTheme.colors.brandDark,
            bgColor3: "var(--background-color)",
        }
    },
    productDetailsConfig:{
        viewPersonality: true,
        button: (props) => <ButtonPrimary {...props} noFocus />,
        className1: "t-body",
        className2: "t-section-title",
        className3: "",
        className4: "t-body-medium",
        className5: "t-body-medium t-font-lato-bold",
        className6: "t-eyebrow t-font-lato-bold",
        className7: "t-copy-heading",
        className8: "t-body",
        className9: "t-body-small t-bold",
        className10: "t-body-medium",
        className11: "t-section-heading",
        className12: "",
        cardText: "ADD TO BAG",
        productDetailsConfigStyled: {
            font: "",
            marginTop: "",
            marginBottom: "",
            margin: "25px 0 -30px 0",
            color1: rebudTheme.colors.accentSativa,
            color2: rebudTheme.colors.accentIndica,
            color3: rebudTheme.colors.accentHybrid,
            color4: rebudTheme.colors.accentCBD,
            color5: rebudTheme.colors.gray8,
        },
        isFirst: "Cannabinoids",
        productDescriptionDetailsConfigStyled: {
            font: "var(--font-artifex)",
            fontWeight: "",
            lineHeight1: "",
            fontSize1: "",
            lineHeight2: "",
            fontSize2: "",
            lineHeight3: "normal",
            letterSpacing: "",
            transition: "",
            font2: "",
            fontSize3: "",
            fontSize4: "",
        },
        productCardConfig: {
            className1: "t-body t-bold",
            className2: "t-body",
            className3: "t-body t-font-lato-bold",
            className4: "t-eyebrow t-font-lato-bold",
            className5: "t-body-small",
            className6: "t-title",
            className7: "t-list-footnote",
            className8: "",
            className9: "",
            className10: "t-body-small",
            productCardConfigStyled: {
                color1: rebudTheme.colors.gray8,
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginRight: "",
                color2: rebudTheme.colors.gray3,
                borderRadius: "",
                font: "",
                bgColor1: rebudTheme.colors.highLightLight,
                borderColor1: "",
                color: "",
                bgColor2: rebudTheme.colors.brandHighLight,
                color3: "",
                lineHeight: "",
                color4: rebudTheme.colors.accentSativa,
                color5: rebudTheme.colors.accentIndica,
                color6: rebudTheme.colors.accentHybrid,
                color7: rebudTheme.colors.accentCBD,
                color8: "",
            },
            cardText: "ADD TO BAG",
            className11: "",
        }
    },
    homeConfig: {
        personalityActive: true,
        dynamicShelfActive: true,
        heroBannerActive:  true,
        brandSpotlightActive:  true,
        productSalesActive:  true,
        staffPicksActive:  true,
        discoverAllActive:  true,
        styles: {
            bgColor: rebudTheme.colors.white,
            fontFamily: '',
            gridTemplate: "repeat(auto-fill, minmax(222px, 1fr))",
        },
        heroBanner:{
            bannerText: true,
            styles:{
                display: "grid",
                gridTemplateColumns: "1fr min(1808px, 100%) 1fr",
                marginTop: "1.5rem",
                minHeight: "394px",
                marginTop1: "3rem",
                marginBottom: "3rem",
                marginLeft: "16px",
                width: "calc(100% - 16px)",
                marginLeft1: "32px",
                width1: "calc(100% - 32px)",
                hover: "scale(1.02)",
                maxWidth: "1744px",
            }
        },
        sectionShelfGridConfig: {
            className1: "t-shelf-heading",
            className2: "t-shelf-link",
            styles:{
                fontFamily: "",
                marginTop: "2.5em",
                color: rebudTheme.colors.brandDark,
                display: "inline-block",
                paddingBottom: "1px",
                backgroundImage: `linear-gradient(${rebudTheme.colors.gray3}, ${rebudTheme.colors.gray3})`,
                backgroundPosition: "0 100%", /*OR bottom left*/
                backgroundSize: "0% 3px",
                backgroundRepeat: "no-repeat",
                transition: "background-size 0.3s, background-position 0s 0.3s",
                position: "relative",
                color1: rebudTheme.colors.gray3,
                margin1: "",
                backgroundPosition1: "100% 100%",
                backgroundSize1: "100% 2px",
                textDecoration: "",
                marginTop1: "",
                borderColor: rebudTheme.colors.brandDark,
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                fontSize2: "",
            }

        },
        brandSpotlightConfig:{
            subHeading: true,
            presentation: true,
            className1: "t-body-medium",
            className2: "t-section-heading",
            className3: "t-body",
            className4: "",
            styles: {
                bgColor: rebudTheme.colors.accentDarkBrown,
                marginLeft: "0",
                width: "calc(100% - 32px)",
                maxWidth: "100%",
                marginLeft1: "0",
                padding: "0 0 0 54px",
                maxWidth1: "1744px",
                width1: "calc(100% - 64px)",
                gridTemplateColumns: "2fr 3fr",
                gridColumnGap: "50px",
                marginLeft2: "0",
                maxWidth2: "1744px",
                width2: "100%",
                padding1: "",
                gridColumn: 1,
                color: rebudTheme.colors.brandDark,
                fontFamily: "",
                textTransform: "",
                paddingBottom: "1.5rem",
                marginBottom: "auto",
                color1: rebudTheme.colors.brandDark,
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                padding2: "12px 10px",
                maxWidth3: "255px",
                width3: "100%",
                border: `1px solid ${rebudTheme.colors.brandDark}`,
                borderRadius: "",
                fontSize2: "",
                color3: "",
                bgColor1: "",
                width4: "",
                padding3: "",
                fontSize3: "",
                bgColor2: rebudTheme.colors.highLightLight,
                gridColumn2: 2,
                display: "",
                justifyContent: "",
                alignItems: "",
                padding4: "",
                paddingTop: "4.75rem",
                color2: rebudTheme.colors.brandDark,
                lineHeight: "",
                color4: rebudTheme.colors.brandDark,
                borderColor: "",
                color5: ""
            }
        },
        brandCardConfig:{
            className1: "t-brand-eyebrow",
            visibleBrandDesc: false,
            styles: {
                paddingLeft : "",
                color: rebudTheme.colors.brandDark,
                color1: rebudTheme.colors.gray3,
                border: ``,
                borderRadius: "",
                width: "100%",
                margin: "",
                paddingLeft1: "",
                marginLeft1: "",
                fontSize: "18px",
                fontFamily: "var(--font-lato)",
            }
        }
    },
    privacyConfig:{
        className1: 't-section-heading',
        fontFamily: '',
        className2: 't-section-subheading',
    },
    termsConfig:{
        className1: 't-section-heading',
        fontFamily: '',
        className2: 't-section-subheading',
        className3: '',
        className4: '',
    }

}
export default configRebud;