import React, { useContext} from "react";
import PropTypes from "prop-types";
import { replaceZero} from "../../../helpers";

import StyledCartItem from "./CartItem.styled";
import 'react-dropdown/style.css'
import {ThemePramsContext} from "../../../App";

const CartItem = React.memo((props) => {
    const {
      imgUrl,
      name,
      category,
      netWeight,
      netWeight_g,
    } = props;

    const {cartItemsStyles} = useContext(ThemePramsContext);
    return (
      <StyledCartItem className="item" themeProps={cartItemsStyles}>
        <div className="item-row">

          <div className="item__node item__node--details">
            <div className="item-img">
              <img src={imgUrl} alt=""/>
            </div>
            <div className="item-text">
              <h3>{name}</h3>
              <h4>{category && category.length ? category.map(item => item.name).join(', '):''}</h4>
              {netWeight && netWeight_g ?
                <h4>{`${replaceZero(netWeight)} ${netWeight_g}`}</h4>
              :''}
            </div>
          </div>
        </div>
      </StyledCartItem>
    )
  }
);

CartItem.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.array,
  netWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  netWeight_g: PropTypes.string,
};

export default CartItem;
